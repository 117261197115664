const httpConstants = {
    METHOD_TYPE: {
        POST: "POST",
        PATCH: "PATCH",
        PUT: "PUT",
        GET: "GET",
        DELETE: "DELETE",
    },
    CONTENT_TYPE: {
        APPLICATION_JSON: "application/json",
        MULTIPART_FORM_DATA: "multipart/form-data",
        APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
        IMAGE_PNG: "image/png",
    },
    DEVICE_TYPE: {
        WEB: "web",
    },
    API_END_POINT: {
        GET_USER_PERMISSIONS: "/get-user-permission",
    },
    combinatorValue: [
        { name: "AND", code: "$and" },
        { name: "OR", code: "$or" },
    ],
    parameterValues: [
        { name: "Tower Load(Wind Load adjusted)", code: "towerDetails.capacity.usedLoadCapacityWithWindLoad" },
        { name: "Free Slots Availability", code: "towerDetails" },
        { name: "Tower Site Type", code: "TowerSiteType" },
        { name: "Tower Type", code: "towerDetails.towerConfig.towerType" },
        { name: "Tower Height", code: "towerDetails.towerHeight.heightfromBase" },
        { name: "Tenancy Count", code: "towerDetails.tenancyCount" },
        { name: "State", code: "siteDetails.location.state" },
        { name: "City", code: "siteDetails.location.city" },
        { name: "Zipcode", code: "siteDetails.location.zipcode" },
        { name: "Wind Zone", code: "siteDetails.environmental.windZone" },
        { name: "Seismic Zone", code: "siteDetails.environmental.seismicZone" },
    ],
    operator_Value: [
        { name: "Equal To", code: "$eq" },
        { name: "Greater Than", code: "$gt" },
        { name: "Less Than", code: "$lt" },
        { name: "Contains", code: "$in" },
        { name: "Greater Than Equal", code: "$gte" },
        { name: "Less Than Equal", code: "$lte" },
    ],
    for_alpha_numeric_operator: [
        { name: "Equal To", code: "$eq" },
        { name: "Contains", code: "$in" },
    ],
    for_elematch_operator: [{ name: "Equal To", code: "$elemMatch" }],
    for_tower_site_type: [{ name: "Equal To", code: "$eq" }],
    for_numeric_operator: [
        { name: "Equal To", code: "$eq" },
        { name: "Greater Than", code: "$gt" },
        { name: "Less Than", code: "$lt" },
        { name: "Greater Than Equal", code: "$gte" },
        { name: "Less Than Equal", code: "$lte" },
    ],
    tower_types: [
        { name: "GBT", code: "GBT" },
        { name: "RTT", code: "RTT" },
        { name: "GBM", code: "GBM" },
        { name: "RTP", code: "RTP" },
    ],
    free_slot: [
        { name: "First", code: "towerDetails.slot0.filled" },
        { name: "Second", code: "towerDetails.slot1.filled" },
        { name: "Third", code: "towerDetails.slot2.filled" },
        { name: "Forth", code: "towerDetails.slot3.filled" },
        { name: "All", code: "towerDetails.tenancyCount" },
    ],
    tower_site_type: [
        { name: "Fiberized", code: "towerDetails.fiberized" },
        { name: "Repeater", code: "towerDetails.repeater" },
    ],
};

const colorPalettes = {
    bar_x_axis_text: "black",
    color1: "#264653",
    color1_1: "#111f25",
    color2: "#2A9D8F",
    "color2_1 ": "#0da291e",
    color3: "#E9C46A",
    color4: "#F4A261",
    color5: "#E76F51",
};

const barChartColors = [colorPalettes.color2, colorPalettes.color4, colorPalettes.color3, colorPalettes.color5, colorPalettes.color1];
const pieChartColors = [colorPalettes.color1, colorPalettes.color5, colorPalettes.color2, colorPalettes.color4, colorPalettes.color5];

module.exports = {
    httpConstants,
    colorPalettes,
    barChartColors,
    pieChartColors,
};
