import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Divider } from 'primereact/divider';
import { BiError, BiBroadcast, BiCloudRain } from "react-icons/bi";
import { BsFillGridFill } from "react-icons/bs";
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { TowerService } from "../../service/TowerService";
import TowerTransitionComponent from "./TowerTransitionComponent";
import { colorPalettes, httpConstants } from "../../constants/index"

const TowerInsightComponent = () => {

    const towetService = new TowerService();
    const unit = useSelector(state => state.changeUnit);
    const [queryForm, setFrom] = useState([{"parameter":"","operator":"","value":""}]);
    const [combinator, setCombinator] = useState("$and");
    const [towers, settowers] = useState(null);
    const [reportTitle, setTitle] = useState('');
    const [validation, setValidation] = useState(false);

    const handelAddRule = (event) => {
        event.preventDefault()
        const inputState = {
            parameter: "",
            operator: "",
            value: ""
        };
        setFrom((prv) => [...prv, inputState]);
    }
    const handelRemove = (index, event) => {
        event.preventDefault();
        setFrom(prev => prev.filter(item => item !== prev[index]));
    }
    const handelInputChange = (index, event, type) => {
        event.preventDefault();
        if(type === "select") {
            setFrom((prv) => {
                return prv.map((item, i) => {
                    if( i !== index ) {
                        return item;
                    }
                    return {
                        ...item, 
                        [event.target.name]: event.value
                    };
                });
            });
        } else {
            setFrom((prv) => {
                return prv.map((item, i) => {
                    if( i !== index ) {
                        return item;
                    }
                    return {
                        ...item, 
                        [event.target.name]: event.target.value
                    };
                });
            });
        }
        if(queryForm[0].parameter !== '' && queryForm[0].operator !== '') {
            setValidation(true);
        } else {
            setValidation(false);
        }
    }

    const handelQuary = () => {
        let com = combinator;
        let conditions = [];
        let value;
        queryForm.forEach((item, index) => {
            if(item.parameter.code === "towerDetails.towerHeight.heightfromBase" || item.parameter.code === "siteDetails.environmental.windZone" || item.parameter.code === "siteDetails.location.zipcode" || item.parameter.code === "siteDetails.environmental.seismicZone" || item.parameter.code === "towerDetails.tenancyCount" || item.parameter.code === "towerDetails.capacity.usedLoadCapacityWithWindLoad") {
                let temp = parseInt(item.value);
                if(item.parameter.code === "towerDetails.towerHeight.heightfromBase" && unit === "FPS") {
                    value = parseFloat((temp / 3.281).toFixed(1));
                } else {
                    value = temp
                }
            } else {
                value = item.value;
            }
            if(item.operator.code === "$in") {
                let val = value.map(item => item.code);
                conditions.push({
                    [item.parameter.code]: { [item.operator.code]: val }  
                 })
            } else if(item.operator.code === "$elemMatch") {
                // conditions.push({
                //     [item.parameter.code]: {
                //         [item.operator.code]: {slot: value.code, filled: "no" }
                //     }
                // })
                if(value.name === 'All') {
                    conditions.push({
                        [value.code]: 0
                    })
                } else {
                    conditions.push({
                        [value.code]: "no"
                    })
                }
                
            } else {
                if(item.parameter.code === "towerDetails.towerConfig.towerType") {
                    conditions.push({
                        [item.parameter.code]: { [item.operator.code]: value.code }  
                    })
                } else if(item.parameter.code === 'TowerSiteType') {
                    conditions.push({
                        [value.code]: 1
                    })
                } else {
                    conditions.push({
                        [item.parameter.code]: { [item.operator.code]: value }  
                    })
                }  
            } 
        });

        let finalQuary = {
            [com] : conditions
        };
        // console.log(finalQuary);
        towetService.getTowerQuery(finalQuary).then((towers) => {
            settowers(towers);
        }) 
    }

    const hadelBack = () => {
        settowers(null);
        setCombinator("$and");
        setFrom([{"parameter":"","operator":"","value":""}]);
    }

    const handelRustClick = () => {
        towetService.getSitesWithRust().then((towers) => {
            settowers(towers);
            setTitle('Sites with Rust');
        });
    }
    const handelNoTenancyClick = () => {
        towetService.getTowerWithNoTenancy().then((towers) => {
            settowers(towers);
            setTitle('Sites with no tenancies');
        })
    }
    const handelFencingDamage = () => {
        towetService.getTowerWithFencingDamage().then((towers) => {
            settowers(towers);
            setTitle('Sites with Boundary/Fencing Damages');
        })
    }
    const handelWindzones = () => {
        towetService.getTowerInHighWindZone().then((towers) => {
            settowers(towers);
            setTitle('Sites in high Wind zones');
        })
    }
    const handelSeismicZones = () => {
        towetService.getTowerInHighSeismicZone().then((towers) => {
            settowers(towers);
            setTitle('Sites in high Seismic Zones');
        })
    }
    const handelDefects = () => {
        towetService.getTowersWithStructuralDefects().then((towers) => {
            settowers(towers);
            setTitle('Sites with Tower Structure defects');
        })
    }
    const handelCapacity = () => {
        towetService.getOverloadSites().then((towers) => {
            settowers(towers);
            setTitle('Sites where Tower loading (wind adjusted) is more than Build load capacity');
        })
    }
    const hanelAllocation = () => {
        towetService.getSiteIDs().then((towers) => {
            let query = [];
            towers.forEach((item)=> {
                query.push({
                    "customerSiteid": item._id
                })
            });
            towetService.getMultiSites(query).then((towers) => {
                settowers(towers);
                setTitle('Sites where loading is more than allocation');
            })
        })
    }

    if(towers && towers.length === 0) {
        return (
            <div className="empty_placeholder flex flex-column align-items-center justify-content-center">
                <h1>
                    <i className="pi pi-search mb-2" style={{ fontSize: "1em" }}></i>
                </h1>
                <h3>No result found</h3>
                <Button label="Go back" onClick={hadelBack} className="mr-2 mb-2" />
            </div>
        );
    } else if(towers){
        return (<TowerTransitionComponent towers={towers} hadelBack={hadelBack} title={reportTitle} />);
    } else {
        return (
            <div className="grid">
                <div className="col-12">
                    <div className="notes p-3 flex align-items-center" style={{background: colorPalettes.color2}}>
                        <div style={{width: '50px', height: '50px', background: 'rgb(255, 255, 255)', borderRadius: '50%', marginRight: '20px', textAlign: 'center', fontSize: '25px', lineHeight: '2.3'}}>
                            <BsFillGridFill />
                        </div>
                        <div>
                            <p className="m-0 text-0">
                                OnDemand Report provides Excel like capability to Query Digital Database with combination of Multiple parameters on different conditions, Additional Query Parameters be added on need basis
                            </p>
                        </div>
                    </div>
                    <h5>OnDemand Report</h5>
                    <hr />
                    <div className="grid">
                        <div className="col-12 lg:col-9">
                            {queryForm.map((item, index) => (
                                <div className="grid query_grid" key={`from-${index}`} style={{position: 'relative'}}>
                                    {
                                        index === 0 ? null : combinator === "$and" ? (
                                            <div className="col-12">
                                                <Divider align="center" type="solid"><b>AND</b></Divider>
                                            </div>
                                        ) : (
                                            <div className="col-12">
                                                <Divider align="center" type="solid"><b>OR</b></Divider>
                                            </div>
                                        )
                                    }
                                    <div className="col-12 lg:col-3">
                                        <Dropdown value={item.parameter} name="parameter" onChange={(e) => handelInputChange(index, e, "select")} options={httpConstants.parameterValues} optionLabel="name" placeholder="Select" className="flex" />
                                    </div>
                                    <div className="col-12 lg:col-3">
                                        {
                                            item.parameter.name === 'Tower Type' ? (
                                                <Dropdown value={item.operator} name="operator" onChange={(e) => handelInputChange(index, e, "select")} options={httpConstants.for_alpha_numeric_operator} optionLabel="name" placeholder="Select" className="flex" />
                                            ) : item.parameter.name === 'Free Slots Availability' ? (
                                                <Dropdown value={item.operator} name="operator" onChange={(e) => handelInputChange(index, e, "select")} options={httpConstants.for_elematch_operator} optionLabel="name" placeholder="Select" className="flex" />
                                            ) : item.parameter.name === 'Tower Site Type' ? (
                                                <Dropdown value={item.operator} name="operator" onChange={(e) => handelInputChange(index, e, "select")} options={httpConstants.for_tower_site_type} optionLabel="name" placeholder="Select" className="flex" />
                                            ) : (
                                                <Dropdown value={item.operator} name="operator" onChange={(e) => handelInputChange(index, e, "select")} options={httpConstants.for_numeric_operator} optionLabel="name" placeholder="Select" className="flex" />
                                            )
                                        }
                                        
                                    </div>
                                    <div className="col-12 lg:col-5">
                                        {
                                            item.parameter.name === 'Tower Type' ? (
                                                item.operator.code === '$in' ? (
                                                    <MultiSelect value={item.value} name="value" options={httpConstants.tower_types} onChange={(e) => handelInputChange(index, e, "select")} optionLabel="name" placeholder="Select Tower Types" display="chip" maxSelectedLabels={4} className="flex" />
                                                ) : (
                                                    <Dropdown value={item.value} name="value" onChange={(e) => handelInputChange(index, e, "select")} options={httpConstants.tower_types} optionLabel="name" placeholder="Select" className="flex" />
                                                ) 
                                            ) : item.parameter.name === 'Free Slots Availability' ? (
                                                <Dropdown value={item.value} name="value" onChange={(e) => handelInputChange(index, e, "select")} options={httpConstants.free_slot} optionLabel="name" placeholder="Select" className="flex" /> 
                                            ) : item.parameter.name === 'Tower Site Type' ? (
                                                <Dropdown value={item.value} name="value" onChange={(e) => handelInputChange(index, e, "select")} options={httpConstants.tower_site_type} optionLabel="name" placeholder="Select" className="flex" /> 
                                            ) : (
                                                <InputText type="text" name="value" onChange={(e) => handelInputChange(index, e, "input")} placeholder="Value" value={item.value} className="w-full" />
                                            )
                                        }
                                        
                                    </div>
                                    <div className="col-1">
                                        <Button icon="pi pi-trash" onClick={(e) => handelRemove(index, e)} className="p-button-danger mr-2 mb-2" style={{background: colorPalettes.color5}}></Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col-12 lg:col-3">
                            <div className="grid">
                                <div className="col-12 md:col-6">
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="option1" name="combinator" value="$and" checked={combinator === '$and'} onChange={(e) => setCombinator(e.value)} />
                                        <label htmlFor="option1">AND</label>
                                    </div>
                                </div>
                                <div className="col-12 md:col-6">
                                    <div className="field-radiobutton">
                                        <RadioButton inputId="option2" name="combinator" value="$or" checked={combinator === '$or'} onChange={(e) => setCombinator(e.value)} />
                                        <label htmlFor="option2">OR</label>
                                    </div>
                                </div>
                            </div>   
                        </div>
                    </div>
                    

                    <Button label="Add Rule" onClick={handelAddRule} className="mr-2 mb-2" style={{background: colorPalettes.color1_1}}/>
                    <Button label="Show Result" onClick={handelQuary} className="mr-2 mb-2" disabled={!validation} style={{background: colorPalettes.color2}} />
                </div>
                <div className="col-12">
                    <div className="grid">
                        <div className="col-12">
                            <hr />
                        </div>
                        <div className="col-12 lg:col-4">
                            <div className="card flex align-items-center justify-content-center flex-column">
                                <div className="icon_wrapper">
                                    <BiError />
                                </div>
                                <h5 className="text-color-secondary">Site with defects</h5>
                                <div className="query_condition">
                                    <h6 className="my-2 p-2 text-blue-600" onClick={handelRustClick}>
                                        <i className="pi pi-angle-right"></i>
                                        Sites with Rust 
                                        <span style={{float: 'right'}}>
                                            <i className="pi pi-exclamation-circle"></i>
                                        </span>
                                    </h6>
                                    <h6 className="my-2 p-2 text-blue-600" onClick={handelDefects}>
                                        <i className="pi pi-angle-right"></i>
                                        Sites with Tower Structure defects
                                        <span style={{float: 'right'}}>
                                            <i className="pi pi-exclamation-circle"></i>
                                        </span>
                                    </h6>
                                    <h6 className="my-2 p-2 text-blue-600" onClick={handelFencingDamage}>
                                        <i className="pi pi-angle-right"></i>
                                        Sites with Boundary/Fencing Damages
                                        <span style={{float: 'right'}}>
                                            <i className="pi pi-exclamation-circle"></i>
                                        </span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-4">
                            <div className="card flex align-items-center justify-content-center flex-column">
                                <div className="icon_wrapper">
                                    <BiBroadcast />
                                </div>
                                <h5 className="text-color-secondary">Tenancy Reports</h5>
                                <div className="query_condition">
                                    <h6 className="my-2 p-2 text-blue-600" onClick={handelNoTenancyClick}>
                                        <i className="pi pi-angle-right"></i>
                                        Sites with no tenancies 
                                        <span style={{float: 'right'}}>
                                            <i className="pi pi-exclamation-circle"></i>
                                        </span>
                                    </h6>
                                    <h6 className="my-2 p-2 text-blue-600" onClick={handelCapacity}>
                                        <i className="pi pi-angle-right"></i>
                                        Sites where Tower loading (wind adjusted) is more than Build load capacity 
                                        <span style={{float: 'right'}}>
                                            <i className="pi pi-exclamation-circle"></i>
                                        </span>
                                    </h6>
                                    <h6 className="my-2 p-2 text-blue-600" onClick={hanelAllocation}>
                                        <i className="pi pi-angle-right"></i>
                                        Sites where loading is more than allocation
                                        <span style={{float: 'right'}}>
                                            <i className="pi pi-exclamation-circle"></i>
                                        </span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-4">
                            <div className="card flex align-items-center justify-content-center flex-column">
                                <div className="icon_wrapper">
                                    <BiCloudRain />
                                </div>
                                <h5 className="text-color-secondary">Environmental Conditions</h5>
                                <div className="query_condition">
                                    <h6 className="my-2 p-2 text-blue-600" onClick={handelWindzones}>
                                        <i className="pi pi-angle-right"></i>
                                        Sites in high Wind zones 
                                        <span style={{float: 'right'}}>
                                            <i className="pi pi-exclamation-circle"></i>
                                        </span>
                                    </h6>
                                    <h6 className="my-2 p-2 text-blue-600" onClick={handelSeismicZones}>
                                        <i className="pi pi-angle-right"></i>
                                        Sites in high Seismic Zones
                                        <span style={{float: 'right'}}>
                                            <i className="pi pi-exclamation-circle"></i>
                                        </span>
                                    </h6>
                                    <h6 className="my-2 p-2 text-blue-600">
                                        <i className="pi pi-angle-right"></i>
                                        Sites with extreme weather in the last 30 days
                                        <span style={{float: 'right'}}>
                                            <i className="pi pi-exclamation-circle"></i>
                                        </span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TowerInsightComponent;