import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { dispatchAction } from "../../../utilities";
import { Divider } from "primereact/divider";
import { Image } from "primereact/image";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chart } from "primereact/chart";
import { Dialog } from "primereact/dialog";
import { BiTrip, BiBorderOuter, BiMapAlt, BiHome, BiWind, BiSun } from "react-icons/bi";
import { WiStrongWind, WiDayCloudy, WiThermometer } from "react-icons/wi";
import { IoAirplaneOutline, IoBoatOutline } from "react-icons/io5";
import { FiActivity } from "react-icons/fi";
import { classNames } from "primereact/utils";
import { Galleria } from "primereact/galleria";
import { Tooltip } from "primereact/tooltip";
import { getPercentageChange } from "../../../utilities";
import { convertValue } from "../../../utilities/unitConversion";
import { mapKey } from "../../../shared/data/const";
import { colorPalettes } from "../../../constants";

const towerTypeNmMap = { GBT: "Self Support", GBM: "Monopole" };

const getLineChartOptions = () => {
    const textColor = "rgba(0, 0, 0, 0.87)";
    const gridLinesColor = "rgba(160, 167, 181, .3)";

    return {
        maintainAspectRatio: false,
        aspectRatio: 1,
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    color: textColor,
                    pointStyle: "circle",
                    boxWidth: 10,
                    boxHeight: 10,
                },
                position: "bottom",
            },
            decimation: {
                enabled: true,
            },
        },
        scales: {
            x: {
                display: false,
                ticks: {
                    color: textColor,
                    callback: function (value, index, ticks) {
                        return moment(value, "YYYY-MM-DD").format("MM-DD");
                    },
                },
                grid: {
                    display: true,
                    offset: true,
                    color: gridLinesColor,
                },
            },
            y: {
                ticks: {
                    color: textColor,
                },
                grid: {
                    display: false,
                    color: gridLinesColor,
                },
            },
        },
    };
};

class DetailsComponent extends Component {
    mapRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            map: null,
            H: null,

            images: null,
            activeIndex: 0,
            showThumbnails: true,
            isAutoPlayActive: false,
            isFullScreen: false,
            displayInfo: false,
            seismicInfo: false,
            windInfo: false,
            markergroup: null,
            mapobject: null,
            chartTempData: null,
            chartWindData: null,
            minTemprature: null,
            maxTemprature: null,
            minWindSpeed: null,
            maxWindSpeed: null,
        };
        this.country = sessionStorage.getItem("region");

        this.responsiveOptions = [
            {
                breakpoint: "1024px",
                numVisible: 5,
            },
            {
                breakpoint: "960px",
                numVisible: 4,
            },
            {
                breakpoint: "768px",
                numVisible: 3,
            },
            {
                breakpoint: "560px",
                numVisible: 1,
            },
        ];
        this.itemTemplate = this.itemTemplate.bind(this);
        this.thumbnailTemplate = this.thumbnailTemplate.bind(this);
        this.onThumbnailChange = this.onThumbnailChange.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onFullScreenChange = this.onFullScreenChange.bind(this);

        this.slnoTemplate = this.slnoTemplate.bind(this);
        this.tbllengthTemplate = this.tbllengthTemplate.bind(this);
        this.tblweidthTemplate = this.tblweidthTemplate.bind(this);
        this.tblheightTemplate = this.tblheightTemplate.bind(this);
        this.tblAreaTemplate = this.tblAreaTemplate.bind(this);
        this.lineChartOptions = getLineChartOptions();
        this.handelDate = this.handelDate.bind(this);
    }
    createmapobject() {
        const H = window.H;
        const platform = new H.service.Platform({
            apikey: mapKey,
        });
        let retobj = {
            map: null,
            H: window.H,
            markergroup: null,
            mapobject: null,
        };
        const defaultLayers = platform.createDefaultLayers();
        if (this.state.map == null) {
            let map = new H.Map(this.mapRef.current, defaultLayers.vector.normal.map, {
                center: { lat: this.props.tower.siteDetails.location.latitude, lng: this.props.tower.siteDetails.location.longitude },
                zoom: 11,
                pixelRatio: window.devicePixelRatio || 1,
            });
            new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
            H.ui.UI.createDefault(map, defaultLayers);
            let markergroup = new H.map.Group();
            map.addObject(markergroup);
            retobj.mapobject = this.addSVGMarkers(H, map, markergroup);
            retobj.map = map;
            retobj.markergroup = markergroup;
            return retobj;
        } else {
            if (this.state.mapobject) this.state.markergroup.removeObject(this.state.mapobject);
            this.state.map.setCenter({ lat: this.props.tower.siteDetails.location.latitude, lng: this.props.tower.siteDetails.location.longitude });
            this.state.map.setZoom(11);
            retobj.mapobject = this.addSVGMarkers(this.state.H, this.state.map, this.state.markergroup);
            retobj.map = this.state.map;
            retobj.markergroup = this.state.markergroup;
            return retobj;
        }
    }
    createimagesobject() {
        let image_url = process.env.REACT_APP_IMAGE_STATIC_URL;
        let images = [];
        if (this.props.tower.siteOverallPhotoImageName !== "") {
            if (this.props.tower.siteOverallPhotoImageName) {
                images.push({
                    itemImageSrc: image_url + this.props.tower.siteOverallPhotoImageName,
                    thumbnailImageSrc: image_url + this.props.tower.siteOverallPhotoImageName,
                    alt: "Site Overall Photo",
                    title: "Site Overall Photo",
                });
            }
        }
        if (this.props.tower.towerDetails.towerConfig.imageName !== "") {
            if (this.props.tower.towerDetails.towerConfig.imageName) {
                images.push({
                    itemImageSrc: image_url + this.props.tower.towerDetails.towerConfig.imageName,
                    thumbnailImageSrc: image_url + this.props.tower.towerDetails.towerConfig.imageName,
                    alt: "Tower config image",
                    title: "Tower Image",
                });
            }
        }
        if (this.props.tower.images) {
            if (this.props.tower.images.hasOwnProperty("towerFoundation") && this.props.tower.images.towerFoundation.length > 0) {
                this.props.tower.images.towerFoundation.forEach((item) => {
                    images.push({
                        itemImageSrc: image_url + item.towerFoundationImageName,
                        thumbnailImageSrc: image_url + item.towerFoundationImageName,
                        alt: "Tower Foundation Image",
                        title: "Tower Foundation Image",
                    });
                });
            }

            if (this.props.tower.images.hasOwnProperty("outDoorBTS") && this.props.tower.images.outDoorBTS.length > 0) {
                this.props.tower.images.outDoorBTS.forEach((item) => {
                    if (item.outdoorBtsImageName !== "") {
                        images.push({
                            itemImageSrc: image_url + item.outdoorBtsImageName,
                            thumbnailImageSrc: image_url + item.outdoorBtsImageName,
                            alt: "Out Door BTS",
                            title: "Out Door BTS",
                        });
                    }
                });
            }

            if (this.props.tower.images.hasOwnProperty("mainGate") && this.props.tower.images.mainGate.length > 0) {
                this.props.tower.images.mainGate.forEach((item) => {
                    if (item.mainGateImageName !== "") {
                        images.push({
                            itemImageSrc: image_url + item.mainGateImageName,
                            thumbnailImageSrc: image_url + item.mainGateImageName,
                            alt: "Main Gate",
                            title: "Main Gate",
                        });
                    }
                });
            }

            if (this.props.tower.images.hasOwnProperty("towerElevation") && this.props.tower.images.towerElevation.length > 0) {
                this.props.tower.images.towerElevation.forEach((item) => {
                    images.push({
                        itemImageSrc: image_url + item.imageName,
                        thumbnailImageSrc: image_url + item.imageName,
                        alt: "Tower Elevation",
                        title: "Tower Elevation",
                    });
                });
            }
        }
        if (this.props.tower.siteDetails.cabinets.length > 0) {
            this.props.tower.siteDetails.cabinets.forEach((element) => {
                if (element.cabinetImageName) {
                    images.push({
                        itemImageSrc: image_url + element.cabinetImageName,
                        thumbnailImageSrc: image_url + element.cabinetImageName,
                        alt: "Cabinet Image",
                        title: "Cabinet",
                    });
                }
            });
        }
        if (this.props.tower.siteDetails.shelters.length > 0) {
            this.props.tower.siteDetails.shelters.forEach((element) => {
                if (element.shelterImageName) {
                    images.push({
                        itemImageSrc: image_url + element.shelterImageName,
                        thumbnailImageSrc: image_url + element.shelterImageName,
                        alt: "Shelters Image",
                        title: "Shelter",
                    });
                }
            });
        }
        return images;
    }
    componentDidUpdate(prevProps) {
        if (this.props.tower !== prevProps.tower || this.props.unit !== prevProps.unit) {
            const { tempData, windData, minTemprature, maxTemprature, minWindSpeed, maxWindSpeed } = this.getChartDataSet(this.props.weatherHistory);
            const { map, H, mapobject, markergroup } = this.createmapobject();
            const images = this.createimagesobject();
            this.setState({
                H: H,
                map: map,
                images: images,
                mapobject: mapobject,
                markergroup: markergroup,
                chartTempData: tempData,
                chartWindData: windData,
                minTemprature: minTemprature,
                maxTemprature: maxTemprature,
                minWindSpeed: minWindSpeed,
                maxWindSpeed: maxWindSpeed,
            });
        }
    }

    componentDidMount() {
        const { tempData, windData, minTemprature, maxTemprature, minWindSpeed, maxWindSpeed } = this.getChartDataSet(this.props.weatherHistory);
        const { map, H, mapobject, markergroup } = this.createmapobject();
        const images = this.createimagesobject();
        this.setState({
            H: H,
            map: map,
            images: images,
            mapobject: mapobject,
            markergroup: markergroup,
            chartTempData: tempData,
            chartWindData: windData,
            minTemprature: minTemprature,
            maxTemprature: maxTemprature,
            minWindSpeed: minWindSpeed,
            maxWindSpeed: maxWindSpeed,
        });
    }

    componentWillUnmount() {
        this.unbindDocumentListeners();
    }

    getChartDataSet = (weatherHistory) => {
        let chartLabel = [],
            chartMinTemp = [],
            chartMaxTemp = [],
            chartWindSpeed = [];
        let response = {};

        let minTempratureValue = Math.min(...weatherHistory.map((item) => item.mintemp_c));
        let maxTempratureValue = Math.max(...weatherHistory.map((item) => item.maxtemp_c));
        let minWindSpeedValue = Math.min(...weatherHistory.map((item) => item.maxwind_kph));
        let maxWindSpeedValue = Math.max(...weatherHistory.map((item) => item.maxwind_kph));

        let minTemprature = weatherHistory.filter((item) => item.mintemp_c === minTempratureValue);
        let maxTemprature = weatherHistory.filter((item) => item.maxtemp_c === maxTempratureValue);
        let minWindSpeed = weatherHistory.filter((item) => item.maxwind_kph === minWindSpeedValue);
        let maxWindSpeed = weatherHistory.filter((item) => item.maxwind_kph === maxWindSpeedValue);

        if (weatherHistory.length > 0) {
            this.windSpeedUnit = this.props.unit === "FPS" ? "mph" : "kmph";
            weatherHistory.forEach((item) => {
                chartLabel.push(item.date);
                chartMinTemp.push(convertValue(item.mintemp_c, "temperature", this.props.unit, "", true));
                chartMaxTemp.push(convertValue(item.maxtemp_c, "temperature", this.props.unit, "", true));
                chartWindSpeed.push(convertValue(item.maxwind_kph, "speed", this.props.unit, this.windSpeedUnit, true));
            });
        }

        response.tempData = {
            labels: chartLabel,
            datasets: [
                {
                    label: "Max",
                    data: chartMaxTemp,
                    fill: false,
                    backgroundColor: colorPalettes.color5,
                    borderColor: colorPalettes.color5,
                    tension: 0,
                    borderWidth: 1.5,
                    pointRadius: 0,
                },
                {
                    label: "Min",
                    data: chartMinTemp,
                    fill: false,
                    backgroundColor: colorPalettes.color2,
                    borderColor: colorPalettes.color2,
                    tension: 0,
                    borderWidth: 1.5,
                    pointRadius: 0,
                },
            ],
        };

        response.windData = {
            labels: chartLabel,
            datasets: [
                {
                    label: "Wind Speed",
                    data: chartWindSpeed,
                    fill: false,
                    backgroundColor: colorPalettes.color1,
                    borderColor: colorPalettes.color1,
                    tension: 0,
                    borderWidth: 1,
                    pointRadius: 0,
                },
            ],
        };

        response.minTemprature = minTemprature[0];
        response.maxTemprature = maxTemprature[0];
        response.minWindSpeed = minWindSpeed[0];
        response.maxWindSpeed = maxWindSpeed[0];

        return response;
    };

    onThumbnailChange(event) {
        this.setState({ activeIndex: event.index });
    }

    onItemChange(event) {
        this.setState({ activeIndex: event.index });
    }

    toggleFullScreen() {
        if (this.state.isFullScreen) {
            this.closeFullScreen();
        } else {
            this.openFullScreen();
        }
    }

    onFullScreenChange() {
        this.setState((prevState) => ({ isFullScreen: !prevState.isFullScreen }));
    }

    openFullScreen() {
        let elem = document.querySelector(".custom-galleria");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    closeFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

    bindDocumentListeners() {
        document.addEventListener("fullscreenchange", this.onFullScreenChange);
        document.addEventListener("mozfullscreenchange", this.onFullScreenChange);
        document.addEventListener("webkitfullscreenchange", this.onFullScreenChange);
        document.addEventListener("msfullscreenchange", this.onFullScreenChange);
    }

    unbindDocumentListeners() {
        document.removeEventListener("fullscreenchange", this.onFullScreenChange);
        document.removeEventListener("mozfullscreenchange", this.onFullScreenChange);
        document.removeEventListener("webkitfullscreenchange", this.onFullScreenChange);
        document.removeEventListener("msfullscreenchange", this.onFullScreenChange);
    }

    thumbnailTemplate(item) {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: "block", width: "80px", height: "80px" }} />;
    }

    itemTemplate(item) {
        if (this.state.isFullScreen) {
            return <img src={item.itemImageSrc} onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")} alt={item.alt} style={{ width: "100%", height: "100%" }} />;
        }

        return <img src={item.itemImageSrc} onClick={() => this.toggleFullScreen()} onError={(e) => (e.target.src = "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")} alt={item.alt} style={{ width: "100%", display: "block" }} />;
    }

    addSVGMarkers = (H, map, markergroup) => {
        let icon =
            '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><path style="fill:#01010100; stroke:none;" d="M0 0L0 32L32 32L32 0L0 0z"/><path style="fill:#ef3d49; stroke:none;" d="M15 28L17 28C19.368 24.4869 21.95 20.9627 23.5208 17C31.413 -2.9091 1.1202 -3.05081 8.2716 16C9.88256 20.2915 12.4852 24.1989 15 28z"/><path style="fill:#ef2121; stroke:none;" d="M15 5L16 6L15 5z"/><path style="fill:#ffffff; stroke:none;" d="M13.7461 7.03241C10.2098 9.89313 14.9493 15.6823 18.3927 12.3781C21.696 9.20841 17.2399 4.20618 13.7461 7.03241z"/><path style="fill:#ff166b; stroke:none;" d="M12 7L13 8L12 7z"/><path style="fill:#36d1ba; stroke:none;" d="M5 27L5 29C11.8299 31.882 20.17 31.882 27 29L27 27L20 25L20 27L24 27L24 28L8 28L8 27L12 27L12 25L5 27z"/></svg>';

        let bearsIcon = new H.map.Icon(icon);
        let bearsMarker = new H.map.Marker({ lat: this.props.tower.siteDetails.location.latitude, lng: this.props.tower.siteDetails.location.longitude }, { icon: bearsIcon }, { width: 30, height: 30 });
        markergroup.addObject(bearsMarker);
        return bearsMarker;
    };

    onInfoClick = () => {
        this.setState({
            displayInfo: true,
            infoText: "Calculated using formula from engineeringtoolbox.com Assumption : Air Density - " + (this.props.unit === "FPS" ? "0.081 pounds/cubic foot" : "1.29 Kg/cubic meter") + " , wind speed: max wind speed at the location",
        });
    };
    seismicInfoClick = () => {
        this.setState({
            seismicInfo: true,
        });
    };
    windInfoClick = () => {
        this.setState({
            windInfo: true,
        });
    };

    slnoTemplate(item) {
        return item.id;
    }
    tbllengthTemplate(item) {
        return convertValue(item.length, "length", this.props.unit);
    }
    tblweidthTemplate(item) {
        return convertValue(item.width, "width", this.props.unit);
    }
    tblheightTemplate(item) {
        return convertValue(item.height, "height", this.props.unit);
    }
    tblAreaTemplate(item) {
        return convertValue(item.area, "area", this.props.unit);
    }
    handelDate(item) {
        return moment(item.date, "YYYY-MM-DD").format("MMM Do, YYYY");
    }

    render = () => {
        const galleriaClassName = classNames("custom-galleria", {
            fullscreen: this.state.isFullScreen,
        });
        return (
            <div className="grid">
                <Dialog header="Info" visible={this.state.displayInfo} className="responsive-dialog" modal onHide={() => this.setState({ displayInfo: false, infoText: null })}>
                    <p>{this.state.infoText}</p>
                </Dialog>
                <Dialog header="Info" visible={this.state.seismicInfo} className="responsive-dialog" modal onHide={() => this.setState({ seismicInfo: false, infoText: null })}>
                    <table>
                        <tbody>
                            <tr>
                                <td>Zone 1:</td>
                                <td>Very small probability of experiencing damaging earth­quake effects.</td>
                            </tr>
                            <tr>
                                <td>Zone 2:</td>
                                <td>Could experience shaking of moderate intensity.</td>
                            </tr>
                            <tr>
                                <td>Zone 3:</td>
                                <td>Could experience strong shaking.</td>
                            </tr>
                            <tr>
                                <td>Zone 4:</td>
                                <td>Could experience very strong shaking</td>
                            </tr>
                            <tr>
                                <td>Zone 5:</td>
                                <td>Near major active faults capable of producing the most intense shaking</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        source:{" "}
                        <a href="https://www.fema.gov/" target="_blank">
                            fema.gov
                        </a>{" "}
                        (The Federal Emergency Management Agency)
                    </p>
                </Dialog>
                <Dialog header="Info" visible={this.state.windInfo} className="responsive-dialog" modal onHide={() => this.setState({ windInfo: false, infoText: null })}>
                    <table>
                        <tbody>
                            <tr>
                                <td>Zone 1:</td>
                                <td>130 mph</td>
                            </tr>
                            <tr>
                                <td>Zone 2:</td>
                                <td>160 mph</td>
                            </tr>
                            <tr>
                                <td>Zone 3:</td>
                                <td>200 mph</td>
                            </tr>
                            <tr>
                                <td>Zone 4:</td>
                                <td>250 mph</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        source:{" "}
                        <a href="https://www.fema.gov/" target="_blank">
                            fema.gov
                        </a>{" "}
                        (The Federal Emergency Management Agency)
                    </p>
                </Dialog>
                <div className="col-12 lg:col-6 xl:col-4 details">
                    <div className="grid">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="flex justify-content-between mb-0">
                                    <div>
                                        <span className="block text-blue-600 font-medium mb-0">Site ID</span>
                                        <div className="text-900 font-medium text-xl">
                                            {this.props.tower.customerSiteid} <br />
                                            <span className="text-blue-500 font-medium text-sm">
                                                ({this.props.tower.siteDetails.location.latitude}, {this.props.tower.siteDetails.location.longitude})
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-column align-items-end">
                                        <div
                                            className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                            style={{
                                                width: "2.5rem",
                                                height: "2.5rem",
                                            }}
                                        >
                                            <i className="pi pi-map-marker text-blue-500 text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                                <span className="text-black-alpha-90">{this.props.tower.siteDetails.location.address}</span>
                                <Divider />
                                <span className="block text-blue-600 font-medium">Tower Type</span>
                                <div className="flex justify-content-between align-items-center mb-0">
                                    <div className="flex-1">
                                        <span className="text-900 font-medium text-xl">
                                            {(towerTypeNmMap[this.props.tower.towerDetails.towerConfig.towerType] ? towerTypeNmMap[this.props.tower.towerDetails.towerConfig.towerType] + " / " : "") + this.props.tower.towerDetails.towerConfig.towerType}
                                        </span>
                                        <div className="w-full flex justify-content-between mt-2">
                                            <div className="flex flex-column align-items-center flex-1 py-2 bg-blue-100">
                                                <span className="mb-1 font-medium">Height From Ground</span>
                                                <span className="text-primary text-xl font-medium">{this.props.tower.towerDetails.towerHeight.heightfromGround ? convertValue(this.props.tower.towerDetails.towerHeight.heightfromGround, "height", this.props.unit) : "--"}</span>
                                            </div>
                                            <div className="flex flex-column align-items-center flex-1 py-2 bg-yellow-100">
                                                <span className="mb-1 font-medium">Height From Base</span>
                                                <span className="text-primary text-xl font-medium">{this.props.tower.towerDetails.towerHeight.heightfromBase ? convertValue(this.props.tower.towerDetails.towerHeight.heightfromBase, "height", this.props.unit) : "--"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-column align-items-end mt-2">
                                        {this.props.tower.towerDetails.towerConfig.imageName ? (
                                            <Image className="ml-1" src={process.env.REACT_APP_IMAGE_STATIC_URL + this.props.tower.towerDetails.towerConfig.imageName} alt="Image" width="80" height="80" preview />
                                        ) : (
                                            <div
                                                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                                style={{
                                                    width: "2.5rem",
                                                    height: "2.5rem",
                                                }}
                                            >
                                                <i className="pi pi-map-marker text-blue-500 text-xl"></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.tower.siteDetails.location.distancefromairports ? (
                            <div className="col-12 md:col-6">
                                <div className="card mb-0">
                                    <span className="block text-blue-600 font-medium mb-3">Nearest Airports</span>
                                    <div className="flex justify-content-between align-items-center mb-0">
                                        <div className="text-900 font-medium text-xl">{this.props.tower.siteDetails.location.distancefromairports ? convertValue(this.props.tower.siteDetails.location.distancefromairports, "distance", this.props.unit) : "Not found"}</div>
                                        <div>
                                            <div
                                                className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl"
                                                style={{
                                                    width: "2.5rem",
                                                    height: "2.5rem",
                                                }}
                                            >
                                                <IoAirplaneOutline />
                                            </div>
                                        </div>
                                    </div>
                                    {this.props.tower.siteDetails.location.hasOwnProperty("nearestAirport") ? <span className="text-blue-600">{this.props.tower.siteDetails.location.nearestAirport.name}</span> : null}
                                </div>
                            </div>
                        ) : null}
                        {this.props.tower.siteDetails.location.distancefromcoast ? (
                            <div className="col-12 md:col-6">
                                <div className="card mb-0">
                                    <span className="block text-blue-600 font-medium mb-3">Distance From Coast</span>
                                    <div className="flex justify-content-between align-items-center mb-0">
                                        <div className="text-900 font-medium text-xl">{this.props.tower.siteDetails.location.distancefromcoast ? convertValue(this.props.tower.siteDetails.location.distancefromcoast, "distance", this.props.unit) : "Not found"}</div>
                                        <div>
                                            <div
                                                className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl"
                                                style={{
                                                    width: "2.5rem",
                                                    height: "2.5rem",
                                                }}
                                            >
                                                <IoBoatOutline />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        {this.props.tower.siteDetails.solarPotential ? (
                            <div className="col-12 md:col-6">
                                <div className="card mb-0">
                                    <span className="block text-blue-600 font-medium mb-3">Solar Potential</span>
                                    <div className="flex justify-content-between align-items-center mb-0">
                                        <div className="text-900 font-medium text-xl">{this.props.tower.siteDetails.solarPotential} Kwh/Sq ft</div>
                                        <div>
                                            <div
                                                className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl"
                                                style={{
                                                    width: "2.5rem",
                                                    height: "2.5rem",
                                                }}
                                            >
                                                <BiSun />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        <div className="col-12 md:col-6">
                            <div className="card mb-0">
                                <span className="block text-blue-600 font-medium mb-3">Fenced</span>
                                <div className="flex justify-content-between align-items-center mb-0">
                                    <div className="text-900 font-medium text-xl">{this.props.tower.siteDetails.physical.fenced}</div>
                                    <div>
                                        <div
                                            className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl"
                                            style={{
                                                width: "2.5rem",
                                                height: "2.5rem",
                                            }}
                                        >
                                            <BiBorderOuter />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6">
                            <div className="card mb-0">
                                <span className="block text-blue-600 font-medium mb-3">Access Road/Distance From Road</span>
                                <div className="flex justify-content-between align-items-center mb-0">
                                    <div className="text-900 font-medium text-xl">
                                        {this.props.tower.siteDetails.access.accessRoadType}/{convertValue(this.props.tower.siteDetails.access.distanceFromRoad, "distance", this.props.unit)}
                                    </div>
                                    <div>
                                        <div
                                            className="flex align-items-center justify-content-center bg-blue-100 border-round  text-blue-500 text-xl"
                                            style={{
                                                width: "2.5rem",
                                                height: "2.5rem",
                                            }}
                                        >
                                            {/* <i className="pi pi-map-marker"></i> */}
                                            <BiTrip />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6">
                            <div className="card mb-0">
                                <span className="block text-blue-600 font-medium mb-3">Motorable/Obstruction</span>
                                <div className="flex justify-content-between align-items-center mb-0">
                                    <div className="text-900 font-medium text-xl">
                                        {this.props.tower.siteDetails.access.motorable}/{this.props.tower.siteDetails.access.obstruction}
                                    </div>
                                    <div>
                                        <div
                                            className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                            style={{
                                                width: "2.5rem",
                                                height: "2.5rem",
                                            }}
                                        >
                                            <i className="pi pi-car text-blue-500 text-xl"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.tower.siteDetails.environmental.seismicZone ? (
                            <div className="col-6 md:col-6">
                                <div className="card mb-0" style={{ position: "relative" }}>
                                    <span className="block text-blue-600 font-medium mb-3">Seismic Zone</span>
                                    <span className="card_info" onClick={() => this.seismicInfoClick()}>
                                        <i className="pi pi-info-circle"></i>
                                    </span>
                                    <div className="flex justify-content-between align-items-center mb-0">
                                        <div className="text-900 font-medium text-xl">{this.props.tower.siteDetails.environmental.seismicZone}</div>
                                        <div>
                                            <div
                                                className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl"
                                                style={{
                                                    width: "2.5rem",
                                                    height: "2.5rem",
                                                }}
                                            >
                                                <FiActivity />
                                            </div>
                                        </div>
                                    </div>
                                    <span className="block text-black-alpha-90 mt-3">{this.props.tower.siteDetails.environmental.seismicZoneDescription}</span>
                                </div>
                            </div>
                        ) : null}
                        {this.props.tower.siteDetails.environmental.windZone ? (
                            <div className="col-6 md:col-6">
                                <div className="card mb-0" style={{ position: "relative" }}>
                                    <span className="block text-blue-600 font-medium mb-3">Wind Zone</span>
                                    <span className="card_info" onClick={() => this.windInfoClick()}>
                                        <i className="pi pi-info-circle"></i>
                                    </span>
                                    <div className="flex justify-content-between align-items-center mb-0">
                                        <div className="text-900 font-medium text-xl">{this.props.tower.siteDetails.environmental.windZone}</div>
                                        <div>
                                            <div
                                                className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl"
                                                style={{
                                                    width: "2.5rem",
                                                    height: "2.5rem",
                                                }}
                                            >
                                                <BiWind />
                                            </div>
                                        </div>
                                    </div>
                                    <span className="block text-black-alpha-90 mt-3">{this.props.tower.siteDetails.environmental.windZoneDescription}</span>
                                </div>
                            </div>
                        ) : null}
                        <div className="col-12">
                            <div className="card mb-3">
                                <span className="block text-blue-600 font-medium mb-3">Site Area</span>
                                <div className="flex justify-content-between align-items-center mb-0">
                                    {this.props.tower.siteDetails.physical.length > 0 ? (
                                        <div className="text-900 font-medium text-xl">
                                            <span className="text-blue-600 text-sm">Length</span> <br />
                                            {convertValue(this.props.tower.siteDetails.physical.length, "length", this.props.unit)}
                                        </div>
                                    ) : (
                                        <div className="text-900 font-medium text-xl">
                                            <span className="text-blue-600 text-sm">Length</span> <br />
                                            --
                                        </div>
                                    )}
                                    {this.props.tower.siteDetails.physical.width > 0 ? (
                                        <div className="text-900 font-medium text-xl">
                                            <span className="text-blue-600 text-sm">Width</span> <br />
                                            {convertValue(this.props.tower.siteDetails.physical.width, "width", this.props.unit)}
                                        </div>
                                    ) : (
                                        <div className="text-900 font-medium text-xl">
                                            <span className="text-blue-600 text-sm">Width</span> <br />
                                            --
                                        </div>
                                    )}

                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Total Area</span> <br />
                                        {convertValue(this.props.tower.siteDetails.physical.totalArea, "area", this.props.unit)}
                                    </div>
                                </div>
                                <Divider />
                                <span className="block text-blue-600 font-medium mb-3">Tower Base</span>
                                <div className="flex justify-content-between align-items-center mb-0">
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Length</span> <br />
                                        {this.props.tower.towerDetails.towerBase.length ? convertValue(this.props.tower.towerDetails.towerBase.length, "length", this.props.unit) : "--"}
                                    </div>
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Width</span> <br />
                                        {this.props.tower.towerDetails.towerBase.width ? convertValue(this.props.tower.towerDetails.towerBase.width, "width", this.props.unit) : "--"}
                                    </div>
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Area</span> <br />
                                        {this.props.tower.towerDetails.towerBase.area ? convertValue(this.props.tower.towerDetails.towerBase.area, "area", this.props.unit) : "--"}
                                    </div>
                                    {/* <div>
                                        <div
                                            className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl"
                                            style={{
                                                width: "2.5rem",
                                                height: "2.5rem",
                                            }}
                                        >
                                            <BiBorderAll />
                                        </div>
                                    </div> */}
                                </div>
                                <Divider />
                                <div className="flex justify-content-between align-items-center mb-0">
                                    {/* <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Built Area</span> <br />
                                        {convertValue(this.props.tower.siteDetails.physical.builtArea, "area", this.props.unit)}
                                    </div> */}
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Used Area</span> <br />
                                        {convertValue(this.props.tower.siteDetails.physical.usedArea, "area", this.props.unit)}
                                    </div>
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Available Area</span> <br />
                                        {convertValue(this.props.tower.siteDetails.physical.availableArea, "area", this.props.unit)}
                                    </div>
                                    <div className="text-900 font-medium text-xl" style={{ width: "95px" }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="card mb-0 p-0">
                                <div className="grid">
                                    {this.props.tower.towerDetails.capacity.usedLoadCapacity ? (
                                        <div className="col-12 md:col-12 lg:col-12 -mb-6">
                                            <div className="card" style={{ boxShadow: "none" }}>
                                                <span className="block text-blue-600 font-medium mb-3">Antenna Weight Load</span>
                                                <div className="flex justify-content-between align-items-center mb-0" style={{ marginTop: "1rem" }}>
                                                    <div className="text-900 font-medium text-xl">{convertValue(this.props.tower.towerDetails.capacity.usedLoadCapacity, "weight", this.props.unit)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}

                                    {this.props.tower.towerDetails.capacity.usedLoadCapacity && this.props.tower.towerDetails.capacity.usedLoadCapacityWithWindLoad ? (
                                        <div className="col-12 md:col-12 lg:col-12">
                                            {this.props.tower.towerDetails.capacity.usedLoadCapacity < this.props.tower.towerDetails.capacity.usedLoadCapacityWithWindLoad ? (
                                                <div className="card" style={{ position: "relative", boxShadow: "none" }}>
                                                    <span className="block text-blue-600 font-medium mb-3">Antenna Wind Adjusted Load</span>
                                                    <span className="card_info" onClick={() => this.onInfoClick()}>
                                                        <i className="pi pi-info-circle"></i>
                                                    </span>

                                                    <div className="flex justify-content-between align-items-center mb-0">
                                                        <div className="text-900 font-medium text-xl">{convertValue(this.props.tower.towerDetails.capacity.usedLoadCapacityWithWindLoad, "weight", this.props.unit)}</div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-8">
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            {this.state.images !== null && this.state.images.length > 0 ? (
                                <div className="card mb-3">
                                    <Galleria
                                        ref={(el) => (this.galleria = el)}
                                        value={this.state.images}
                                        activeIndex={this.state.activeIndex}
                                        onItemChange={this.onItemChange}
                                        showThumbnails={this.state.showThumbnails}
                                        showItemNavigators
                                        showItemNavigatorsOnHover
                                        numVisible={3}
                                        circular
                                        transitionInterval={3000}
                                        responsiveOptions={this.responsiveOptions}
                                        item={this.itemTemplate}
                                        thumbnail={this.thumbnailTemplate}
                                        //footer={footer}
                                        style={{ maxWidth: "640px" }}
                                        className={galleriaClassName}
                                    />
                                </div>
                            ) : null}
                            <div className="card p-0">
                                <div
                                    ref={this.mapRef}
                                    style={{
                                        width: "100%",
                                        height: "319px",
                                        overflow: "hidden",
                                    }}
                                ></div>
                            </div>
                            <div className="card mb-3">
                                {/* <span className="block text-blue-600 font-medium mb-3">Site Details</span> */}
                                <div className="flex justify-content-between align-items-center mb-0 capitalize">
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Site Category</span>
                                        <br />
                                        {this.props.tower.siteDetails.siteCategory}
                                    </div>
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Site Type</span> <br />
                                        {this.props.tower.siteDetails.siteType}
                                    </div>
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm ">Zoning Area</span> <br />
                                        {this.props.tower.siteDetails.zoningArea !== "" ? this.props.tower.siteDetails.zoningArea : "--"}
                                    </div>
                                    <div>
                                        <div
                                            className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl"
                                            style={{
                                                width: "2.5rem",
                                                height: "2.5rem",
                                            }}
                                        >
                                            <BiMapAlt />
                                        </div>
                                    </div>
                                </div>
                                <Divider />
                                <div className="flex justify-content-between align-items-center mb-0">
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Site Ownership</span> <br />
                                        {this.props.tower.siteDetails.siteOwnership ? this.props.tower.siteDetails.siteOwnership : "--"}
                                    </div>
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Geographic Density</span> <br />
                                        {this.props.tower.siteDetails.siteGeographicalDensity ? this.props.tower.siteDetails.siteGeographicalDensity : "--"}
                                    </div>
                                    <div>
                                        <div
                                            className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl"
                                            style={{
                                                width: "2.5rem",
                                                height: "2.5rem",
                                            }}
                                        >
                                            <BiHome />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6">
                            {this.country !== "in" ? (
                                <div className="card mb-3">
                                    <div className="flex flex-wrap justify-content-between mb-0">
                                        {this.props.tower.siteDetails.physical.length > 0 && this.props.tower.siteDetails.physical.width > 0 ? (
                                            <div className="text-900 font-medium text-xl">
                                                <span className="text-blue-600 text-sm">Site Free Space</span>
                                                <br />
                                                <div className="gauge" style={{ width: "160px" }}>
                                                    <div className="percentage" style={{ transform: "rotate(" + Math.abs(getPercentageChange(this.props.tower.siteDetails.physical.totalArea, this.props.tower.siteDetails.physical.usedArea)) * 1.8 + "deg)" }}></div>
                                                    <div className="mask"></div>
                                                    <span className="value">{Math.abs(getPercentageChange(this.props.tower.siteDetails.physical.totalArea, this.props.tower.siteDetails.physical.usedArea)) + " %"}</span>
                                                </div>
                                            </div>
                                        ) : null}
                                        {this.props.tower.towerDetails.capacity.usedLoadCapacity && this.props.tower.towerDetails.capacity.usedLoadCapacityWithWindLoad ? (
                                            <div className="text-900 font-medium text-xl">
                                                <span className="text-blue-600 text-sm">Wind Impact On Load</span> <br />
                                                {Math.abs(getPercentageChange(this.props.tower.towerDetails.capacity.usedLoadCapacity, this.props.tower.towerDetails.capacity.usedLoadCapacityWithWindLoad)) + " %"}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            ) : null}

                            {this.props.currentWeather ? (
                                <div className="card mb-3 bg-blue-100">
                                    <div className="flex justify-content-between align-items-center">
                                        <div className="text-blue-500 wi-ioc">
                                            <WiDayCloudy />
                                            <span className="block text-sm text-700">
                                                {this.props.currentWeather.weather[0].main}, {this.props.currentWeather.weather[0].description}
                                            </span>
                                        </div>
                                        <div className="text-right">
                                            <span className="text-lg text-700 block">
                                                <WiThermometer /> {this.props.currentWeather.main.humidity}% &nbsp; &nbsp;
                                                <WiStrongWind /> {convertValue(this.props.currentWeather.wind.speed, "speed", this.props.unit, "mph")}
                                            </span>
                                            <span className="text-4xl font-bold block">{convertValue(this.props.currentWeather.main.temp, "temperature", this.props.unit)}</span>
                                            <span className="text-sm text-700 block">Feels like {convertValue(this.props.currentWeather.main.feels_like, "temperature", this.props.unit)}</span>
                                        </div>
                                    </div>
                                    {/* <p className="mt-2">As on {moment(this.props.currentTime).tz('America/Los_Angeles').format('MMM DD, HH:mm A')}</p> */}
                                    {/* <p className="mt-2">As on {moment(this.props.currentTime).format("MMM DD, HH:mm A")}</p> */}
                                </div>
                            ) : null}
                            {/* <div className="card mb-3">
                                <span className="block text-blue-600 font-medium mb-3">Historical Weather Conditions (1 year)</span>
                                <div className="flex justify-content-between align-items-center mb-0">
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Max Temp</span> <br />
                                        {convertValue(this.props.historicalWeather.maxTemp, "temperature", this.props.unit)}
                                    </div>
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Min Temp</span> <br />
                                        {convertValue(this.props.historicalWeather.minTemp, "temperature", this.props.unit)}
                                    </div>
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Max Wind Speed</span> <br />
                                        {convertValue(this.props.historicalWeather.maxWindSpeed, "speed", this.props.unit, "mph")}
                                    </div>
                                    <div className="text-900 font-medium text-xl">
                                        <span className="text-blue-600 text-sm">Total Precipitation</span> <br />
                                        {convertValue(this.props.historicalWeather.totalprecip, "precipitation", this.props.unit)}
                                    </div>
                                </div>
                            </div> */}
                            {this.state.chartTempData ? (
                                <div className="card mb-3">
                                    <span className="block text-blue-600 font-medium mb-3">Temperature Trends</span>
                                    <Chart type="line" data={this.state.chartTempData} options={this.lineChartOptions} height="100"></Chart>
                                    <div className="w-full flex justify-content-between mt-2">
                                        <div className="flex flex-column align-items-center flex-1 py-2 bg-blue-100">
                                            <span className="mb-1 font-medium">Max Temperature</span>
                                            <span className="text-primary">
                                                {convertValue(this.state.maxTemprature.maxtemp_c, "temperature", this.props.unit)} on {moment(this.state.maxTemprature.date, "YYYY-MM-DD").format("MMM Do, YYYY")}
                                            </span>
                                        </div>
                                        <div className="flex flex-column align-items-center flex-1 py-2 bg-yellow-100">
                                            <span className="mb-1 font-medium">Min Temperature</span>
                                            <span className="text-primary">
                                                {convertValue(this.state.minTemprature.mintemp_c, "temperature", this.props.unit)} on {moment(this.state.minTemprature.date, "YYYY-MM-DD").format("MMM Do, YYYY")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {this.state.chartWindData ? (
                                <div className="card mb-3">
                                    <span className="block text-blue-600 font-medium mb-3">Wind Speed Trends</span>
                                    <Chart type="line" data={this.state.chartWindData} options={this.lineChartOptions} height="100"></Chart>
                                    <div className="w-full flex justify-content-between mt-2">
                                        <div className="flex flex-column align-items-center flex-1 py-2 bg-blue-100">
                                            <span className="mb-1 font-medium">Max Wind Speed</span>
                                            <span className="text-primary">
                                                {convertValue(this.state.maxWindSpeed.maxwind_kph, "speed", this.props.unit, this.windSpeedUnit)} on {moment(this.state.maxWindSpeed.date, "YYYY-MM-DD").format("MMM Do, YYYY")}
                                            </span>
                                        </div>
                                        <div className="flex flex-column align-items-center flex-1 py-2 bg-yellow-100">
                                            <span className="mb-1 font-medium">Min Wind Speed</span>
                                            <span className="text-primary">
                                                {convertValue(this.state.minWindSpeed.maxwind_kph, "speed", this.props.unit, this.windSpeedUnit)} on {moment(this.state.minWindSpeed.date, "YYYY-MM-DD").format("MMM Do, YYYY")}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {this.props.weatherEvent.length > 0 ? (
                                <div className="card mb-3">
                                    <span className="block text-blue-600 font-medium mb-3">Weather Events</span>
                                    <DataTable value={this.props.weatherEvent} responsiveLayout="scroll">
                                        <Column field="index" header="Sl No."></Column>
                                        <Column field="name" header="Event Name"></Column>
                                        <Column header="Date" body={this.handelDate}></Column>
                                    </DataTable>
                                </div>
                            ) : null}

                            {this.props.tower.siteDetails.cabinets.length > 0 ? (
                                <div className="card mb-0">
                                    <span className="block text-blue-600 font-medium mb-3">Cabinet</span>
                                    <DataTable value={this.props.tower.siteDetails.cabinets} responsiveLayout="scroll">
                                        {/* <Column header="ID" body={this.slnoTemplate}></Column> */}
                                        <Column header="Length" body={this.tbllengthTemplate}></Column>
                                        <Column header="Weidth" body={this.tblweidthTemplate}></Column>
                                        <Column header="Height" body={this.tblheightTemplate}></Column>
                                        <Column header="Area" body={this.tblAreaTemplate}></Column>
                                    </DataTable>
                                </div>
                            ) : null}
                            {this.props.tower.siteDetails.shelters.length > 0 ? (
                                <div className="card mb-0">
                                    <span className="block text-blue-600 font-medium mb-3">Shelter</span>
                                    <DataTable value={this.props.tower.siteDetails.shelters} responsiveLayout="scroll">
                                        {/* <Column header="ID" body={this.slnoTemplate}></Column> */}
                                        <Column header="Length" body={this.tbllengthTemplate}></Column>
                                        <Column header="Weidth" body={this.tblweidthTemplate}></Column>
                                        <Column header="Height" body={this.tblheightTemplate}></Column>
                                        <Column header="Area" body={this.tblAreaTemplate}></Column>
                                    </DataTable>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state) => {
    return { unit: state.changeUnit };
};
export default connect(mapStateToProps, { dispatchAction })(DetailsComponent);
