import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../Actions/index";
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import TenantService from "../service/tenant";
import { changeUnit } from "../Actions/index";
const poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENTID,
};

const userPool = new CognitoUserPool(poolData);

const Login = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [errMsg, setErrMsg] = useState(null);
    const [disableBtn, setBtnDisabled] = useState(false);
    const [passwordType, setPasswordType] = useState("password");

    const history = useHistory();

    const goDashboard = () => {
        history.push("/");
    };

    const userLogin = async (e) => {
        setBtnDisabled(true);
        let loginResponse = await cognitoLogin();
        if (!loginResponse || !loginResponse.accessToken || !loginResponse.idToken || !loginResponse.idToken.payload.sub || !loginResponse.idToken.payload.email) return;
        dispatch(login(loginResponse));
        sessionStorage.setItem("token", loginResponse.idToken.jwtToken);
        sessionStorage.setItem("username", loginResponse.idToken.payload.name);
        sessionStorage.setItem("email", loginResponse.idToken.payload.email);
        getPermissionAndNavigate(loginResponse.idToken.jwtToken);
    };

    const getPermissionAndNavigate = async (token) => {
        let getPermissionResponse = await TenantService.getPermissions(token);
        let serviceIds = [];
        if (getPermissionResponse && getPermissionResponse.length) {
            getPermissionResponse.forEach((p) => {
                if (p.serviceTypeName === "Towers") {
                    if (p.service.region === "us" || p.service.region === "US") {
                        dispatch(changeUnit("FPS"));
                        sessionStorage.setItem("unitsystem", "FPS");
                    } else {
                        dispatch(changeUnit("MKS"));
                        sessionStorage.setItem("unitsystem", "MKS");
                    }
                    serviceIds.push({ id: p.serviceId, image: p.image });
                    sessionStorage.setItem("states", JSON.stringify(p.states ? p.states : []));
                    sessionStorage.setItem("cities", JSON.stringify(p.cities ? p.cities : []));
                    sessionStorage.setItem("zipcodes", JSON.stringify(p.zipcodes ? p.zipcodes : []));
                    sessionStorage.setItem("region", p.service.region);
                }
            });
            if (serviceIds.length) {
                sessionStorage.setItem("serviceId", serviceIds[0].id);
                sessionStorage.setItem("logo", serviceIds[0].image);

                goDashboard();
            } else {
                setErrMsg("Tower service is unavailable");
                setBtnDisabled(false);
            }
        }
    };

    const cognitoLogin = async () => {
        let loginDetails = {
            Username: email.toLowerCase(),
            Password: password,
        };
        let authenticationDetails = new AuthenticationDetails(loginDetails);

        let userData = {
            Username: email.toLowerCase(),
            Pool: userPool,
        };

        let cognitoUser = new CognitoUser(userData);

        return await new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (data) => {
                    resolve(data);
                    setBtnDisabled(true);
                },
                onFailure: (err) => {
                    reject(err.message);

                    if (err.message) {
                        setErrMsg(err.message);
                        setBtnDisabled(false);
                    }
                },
            });
        });
    };

    const togglePassword = (e) => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    const onforgotPassword = () => {};

    return (
        <div className="pages-body flex flex-column login-img">
            <div className="align-self-center mt-auto mb-auto">
                <div className="pages-panel card flex flex-column">
                    <div className="Row-fixing">
                        <img alt="" src="./images/nexLogo.svg" className="logo-fixing" />
                        <br />
                        <br />
                    </div>
                    <div className="pages-detail mb-6 px-6">Sign in to your Nextqore account</div>
                    <form onSubmit={userLogin}>
                        <div className="input-panel flex flex-column px-3">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-envelope"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText type="text" id="inputgroup1" onChange={(e) => setEmail(e.target.value)} />
                                </span>
                            </div>
                            <div className="p-inputgroup mt-3 mb-3">
                                <span className="p-inputgroup-addon">
                                    <i className="pi pi-lock"></i>
                                </span>
                                <span className="p-float-label">
                                    <InputText style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} type={passwordType} id="inputgroup2" onChange={(e) => setPassword(e.target.value)} />

                                    <a className="p-inputgroup-addon" onClick={togglePassword}>
                                        {passwordType === "password" ? <i className="pi pi-eye-slash"></i> : <i className="pi pi-eye"></i>}
                                    </a>
                                </span>
                            </div>

                            {/* <div>
                                <div className="blue-text" style={{ float: "right" }} onClick={() => onforgotPassword()}>
                                    Forgot Password ?
                                </div>
                            </div> */}
                            <div className="errorstyle">{errMsg}</div>
                        </div>
                        <Button className="login-button mb-3 px-3" label="LOGIN" disabled={disableBtn} onClick={userLogin}></Button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
