const initialState = sessionStorage.getItem("unitsystem");

const changeUnit = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGEUNIT":
            return (state = action.payload);
        default:
            return state;
    }
};
export default changeUnit;
