import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FiBatteryCharging } from 'react-icons/fi';
import { IoIosSnow, IoMdSnow } from 'react-icons/io';

import { TowerService } from '../../service/TowerService';

class ConfigurationComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dieselGenerator: [],
			precisionAC: [],
			splitAC: [],
			batteryBank: [],
			equepment_count: {
				total_DG: 0,
				total_precision_AC: 0,
				total_split_AC: 0,
				total_battery_bank: 0
			}
		};
		this.towerService = new TowerService();
	}

	componentDidMount = () => {
		let equepment_count = {
			total_DG: 0,
			total_precision_AC: 0,
			total_split_AC: 0,
			total_battery_bank: 0
		};

		Promise.all([this.towerService.getAllTowers(), this.towerService.getTowersWithDG(), this.towerService.getTowerWithSplitAC(), this.towerService.getTowerWithPrecisionAC(), this.towerService.getTowerWithBatteryBank()]).then(response => {
			let towers = response[0];
			let towersWithDG = response[1];
			let towerWithSplitAC = response[2];
			let towerWithPrecisionAC = response[3];
			let towerWithBatteryBank = response[4]; 

			towers.map(item => {
				if(item.siteEquipment.hasOwnProperty("dieselGenerator")) {
					equepment_count.total_DG += item.siteEquipment.dieselGenerator.count;
				}

				if(item.siteEquipment.hasOwnProperty("precisionAc")) {
					item.siteEquipment.precisionAc.count !== '' ? equepment_count.total_precision_AC += item.siteEquipment.precisionAc.count : 
					equepment_count.total_precision_AC += 0;
				}

				if(item.siteEquipment.hasOwnProperty("splitAC")) {
					item.siteEquipment.splitAC.count !== '' ? equepment_count.total_split_AC += item.siteEquipment.splitAC.count : 
					equepment_count.total_split_AC += 0;
				}

				if(item.siteEquipment.hasOwnProperty("batteryBank")) {
					item.siteEquipment.batteryBank.present !== '' ? equepment_count.total_battery_bank +=1 : equepment_count.total_battery_bank += 0;
				}
			});

			let dg_capacity = [];
			towersWithDG.forEach(item => {
				if (dg_capacity.length === 0) {
					dg_capacity.push(item.siteEquipment.dieselGenerator.capacity);
				} else if(!dg_capacity.includes(item.siteEquipment.dieselGenerator.capacity)) {
					dg_capacity.push(item.siteEquipment.dieselGenerator.capacity);
				}
			});
			let dieselGenerator = dg_capacity.map((item, index) => {
				let sites = towersWithDG.filter(elm => elm.siteEquipment.dieselGenerator.capacity === item);

				let t_state = [];
				sites.forEach(site => {
					if (t_state.length === 0) {
						t_state.push(site.siteDetails.location.state);
					} else if(!t_state.includes(site.siteDetails.location.state)) {
						t_state.push(site.siteDetails.location.state);
					}
				});

				let details = t_state.map((state, index) => {
					let t_state_list = sites.filter(elm => elm.siteDetails.location.state === state);
					return {
						id: 'DGC0' + (index+1),
						name: state,
						total: t_state_list.length
					}
				});

				return {
					id: 'DG0' + (index+1),
					capacity: item !== '' ? item : 'Unspecified',
					total: sites.length,
					details: details
				}
			});

			let split_AC_capacity = [];
			towerWithSplitAC.forEach(item => {
				if (split_AC_capacity.length === 0) {
					split_AC_capacity.push(item.siteEquipment.splitAC.capacity);
				} else if(!split_AC_capacity.includes(item.siteEquipment.splitAC.capacity)) {
					split_AC_capacity.push(item.siteEquipment.splitAC.capacity);
				}
			});
			let splitAC = split_AC_capacity.map((item, index) => {
				let sites = towersWithDG.filter(elm => elm.siteEquipment.splitAC.capacity === item);

				let t_state = [];
				sites.forEach(site => {
					if (t_state.length === 0) {
						t_state.push(site.siteDetails.location.state);
					} else if(!dg_capacity.includes(site.siteDetails.location.state)) {
						t_state.push(site.siteDetails.location.state);
					}
				});

				let details = t_state.map((state, index) => {
					let t_state_list = sites.filter(elm => elm.siteDetails.location.state === state);
					return {
						id: 'SPCC0' + (index+1),
						name: state,
						total: t_state_list.length
					}
				})

				return {
					id: 'SPC0' + (index+1),
					capacity: item !== '' ? item : 'Unspecified',
					total: sites.length,
					details: details
				}
			});

			let precision_AC_capacity = [];
			towerWithPrecisionAC.forEach(item => {
				if (precision_AC_capacity.length === 0) {
					precision_AC_capacity.push(item.siteEquipment.precisionAc.capacity);
				} else if(!precision_AC_capacity.includes(item.siteEquipment.precisionAc.capacity)) {
					precision_AC_capacity.push(item.siteEquipment.precisionAc.capacity);
				}
			});
			let precisionAC = precision_AC_capacity.map((item, index) => {
				let sites = towersWithDG.filter(elm => elm.siteEquipment.precisionAc.capacity === item);

				let t_state = [];
				sites.forEach(site => {
					if (t_state.length === 0) {
						t_state.push(site.siteDetails.location.state);
					} else if(!dg_capacity.includes(site.siteDetails.location.state)) {
						t_state.push(site.siteDetails.location.state);
					}
				});

				let details = t_state.map((state, index) => {
					let t_state_list = sites.filter(elm => elm.siteDetails.location.state === state);
					return {
						id: 'PACC0' + (index+1),
						name: state,
						total: t_state_list.length
					}
				})

				return {
					id: 'PAC0' + (index+1),
					capacity: item !== '' ? item : 'Unspecified',
					total: sites.length,
					details: details
				}
			});

			let battery_bank_capacity = [];
			towerWithBatteryBank.forEach(item => {
				if (battery_bank_capacity.length === 0) {
					battery_bank_capacity.push(item.siteEquipment.batteryBank.batteryBankCapacity);
				} else if(!battery_bank_capacity.includes(item.siteEquipment.batteryBank.batteryBankCapacity)) {
					battery_bank_capacity.push(item.siteEquipment.batteryBank.batteryBankCapacity);
				}
			});
			let batteryBank = battery_bank_capacity.map((item, index) => {
				let sites = towersWithDG.filter(elm => elm.siteEquipment.batteryBank.batteryBankCapacity === item);

				let t_state = [];
				sites.forEach(site => {
					if (t_state.length === 0) {
						t_state.push(site.siteDetails.location.state);
					} else if(!dg_capacity.includes(site.siteDetails.location.state)) {
						t_state.push(site.siteDetails.location.state);
					}
				});

				let details = t_state.map((state, index) => {
					let t_state_list = sites.filter(elm => elm.siteDetails.location.state === state);
					return {
						id: 'BBCC0' + (index+1),
						name: state,
						total: t_state_list.length
					}
				})

				return {
					id: 'BBC0' + (index+1),
					capacity: item !== '' ? item : 'Unspecified',
					total: sites.length,
					details: details
				}
			});

			this.setState({
				dieselGenerator,
				precisionAC,
				splitAC,
				batteryBank,
				equepment_count
			});
		});
	}

	rowExpansionTemplate(data) {
		return (
			<div className='orders-subtable'>
				<DataTable value={data.details} responsiveLayout='scroll'>
					<Column field='name' header='State'></Column>
					<Column field='total' header='No of sites' sortable></Column>
				</DataTable>
			</div>
		);
	}

	render = () => {
		return (
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="card">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-xl font-medium mb-3">Total No. of Diesel Generator</span>
                                <div className="text-900 font-medium text-xl">{this.state.equepment_count.total_DG}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-yellow-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <i className="pi pi-cog text-yellow-500 text-xl" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="card">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-xl font-medium mb-3">Total No. of Split AC</span>
                                <div className="text-900 font-medium text-xl">{this.state.equepment_count.total_split_AC}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-green-100 border-round text-green-500 text-xl" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <IoMdSnow />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="card">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-xl font-medium mb-3">Total No. of Precision AC</span>
                                <div className="text-900 font-medium text-xl">{this.state.equepment_count.total_precision_AC}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-blue-100 border-round text-blue-500 text-xl" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <IoIosSnow />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="card">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <span className="block text-xl font-medium mb-3">Total No. of Battery Bank</span>
                                <div className="text-900 font-medium text-xl">{this.state.equepment_count.total_battery_bank}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center bg-orange-100 border-round text-orange-500 text-xl" style={{ width: "2.5rem", height: "2.5rem" }}>
                                <FiBatteryCharging />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 lg:col-3">
                    {this.state.dieselGenerator.length > 0 ? (
                        <div className="card">
                            <h5 className="mb-3 text-xl">Diesel Generator</h5>
                            <DataTable value={this.state.dieselGenerator} expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })} responsiveLayout="scroll" rowExpansionTemplate={this.rowExpansionTemplate} dataKey="id">
                                <Column expander style={{ width: "3em" }} />
                                <Column field="capacity" header="Capacity" />
                                <Column field="total" header="Total" />
                            </DataTable>
                        </div>
                    ) : null}
                </div>
				<div className="col-12 lg:col-3">
                    {this.state.splitAC.length > 0 ? (
                        <div className="card">
                            <h5 className="mb-3 text-xl">Split AC</h5>
                            <DataTable value={this.state.splitAC} expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })} responsiveLayout="scroll" rowExpansionTemplate={this.rowExpansionTemplate} dataKey="id">
                                <Column expander style={{ width: "3em" }} />
                                <Column field="capacity" header="Capacity" />
                                <Column field="total" header="Total" />
                            </DataTable>
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-3">
                    {this.state.precisionAC.length > 0 ? (
                        <div className="card">
                            <h5 className="mb-3 text-xl">Precision AC</h5>
                            <DataTable value={this.state.precisionAC} expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })} responsiveLayout="scroll" rowExpansionTemplate={this.rowExpansionTemplate} dataKey="id">
                                <Column expander style={{ width: "3em" }} />
                                <Column field="capacity" header="Capacity" />
                                <Column field="total" header="Total" />
                            </DataTable>
                        </div>
                    ) : null}
                </div>
                <div className="col-12 lg:col-3">
                    {this.state.batteryBank.length > 0 ? (
                        <div className="card">
                            <h5 className="mb-3 text-xl">Battery Bank</h5>
                            <DataTable value={this.state.batteryBank} expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setState({ expandedRows: e.data })} responsiveLayout="scroll" rowExpansionTemplate={this.rowExpansionTemplate} dataKey="id">
                                <Column expander style={{ width: "3em" }} />
                                <Column field="capacity" header="Capacity" />
                                <Column field="total" header="Total" />
                            </DataTable>
                        </div>
                    ) : null}
                </div>
            </div>
        );
	};
}

export default ConfigurationComponent;
