import React, { Component } from "react";

// import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { TabView, TabPanel } from "primereact/tabview";
import DetailsComponent from "./Components/DetailsComponent";
import DefeactsComponent from "./Components/DefeactsComponent";
import TenancyComponent from "./Components/TenancyComponent";
import { TowerService } from "../../service/TowerService";
import ReportComponent from "./Components/reportComponent";
import MyxComponent from "./Components/myxComponent";
import MyxRevitComponent from "./Components/myxRevitComponent";
import EquipmentComponent from "./Components/EquipmentComponent";
import CustomReportComponent from "./Components/customReportComponent";

class MenuSiteComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            site: null,
            weather: null,
            historicalWeather: null,
            weatherEvent: [],
            weatherHistory: [],
        };

        this.towerService = new TowerService();
    }
    fetchdata = () => {
        // console.log("Ids do not matach")
        const { towerid, lat, lng } = this.props.match.params;
        // console.log(towerid, lat, lng)
        let currentState = {
            site: null,
            weather: null,
            historicalWeather: null,
            weatherEvent: [],
        };
        let position = {
            lat: lat,
            lng: lng,
        };
        let to_data = moment().format("YYYY-MM-DD");
        let from_date = moment().subtract(1, "years").format("YYYY-MM-DD");
        Promise.all([
            this.towerService.getMyxOneTower(parseInt(towerid)),
            this.towerService.getCurrentWeather(position),
            this.towerService.getHistoricalWeather(parseInt(towerid)),
            this.towerService.getWeatherEvents(parseInt(towerid)),
            this.towerService.getWeatherGraphData(parseInt(towerid), from_date, to_data),
        ])
            .then((response) => {
                // console.log(response)
                currentState.site = response[0][0];
                currentState.weather = response[1];
                currentState.historicalWeather = response[2][0];
                let weatherEvent = response[3];

                let weatherHistory = response[4];
                weatherHistory.sort((a, b) => new Date(b.date) - new Date(a.date));

                let weatherEventArray = [];
                if (weatherEvent.length > 0) {
                    weatherEvent.forEach((item, index) => {
                        weatherEventArray.push({
                            index: index + 1,
                            name: item._id.condition,
                            date: item.dates[0],
                        });
                    });
                }
                currentState.weatherEvent = weatherEventArray;
                currentState.weatherHistory = weatherHistory;

                this.setState(currentState);
            })
            .catch();
    };

    componentDidUpdate(prevProps) {
        if (this.props.match.params.towerid !== prevProps.match.params.towerid) {
            // ... write code to get new data using new prop, also update your state
            this.fetchdata();
        }
    }

    componentDidMount() {
        this.fetchdata();
    }

    render = () =>
        this.state.site === null && this.state.weather === null && this.state.historicalWeather === null ? null : (
            <TabView>
                <TabPanel header="Site Details">
                    <DetailsComponent tower={this.state.site} currentWeather={this.state.weather} historicalWeather={this.state.historicalWeather} weatherEvent={this.state.weatherEvent} weatherHistory={this.state.weatherHistory} />
                </TabPanel>
                <TabPanel header="Defects">
                    <DefeactsComponent tower={this.state.site} />
                </TabPanel>
                <TabPanel header="Tenancy">
                    <TenancyComponent tower={this.state.site} />
                </TabPanel>
                {this.state.site.report ? (
                    <TabPanel header="Site Report">
                        <ReportComponent site={this.state.site} />
                    </TabPanel>
                ) : null}
                {this.state.site.myxlink ? (
                    <TabPanel header="Digital Twin">
                        <MyxComponent site={this.state.site} />
                    </TabPanel>
                ) : null}
                {this.state.site.reviturl ? (
                    <TabPanel header="BIM Model">
                        <MyxRevitComponent site={this.state.site} />
                    </TabPanel>
                ) : null}
                {this.state.site.customReport ? (
                    <TabPanel header="Custom Report">
                        <CustomReportComponent site={this.state.site} />
                    </TabPanel>
                ) : null}

                <TabPanel header="Site Equipment">
                    <EquipmentComponent tower={this.state.site} />
                </TabPanel>
            </TabView>
        );
}

export default MenuSiteComponent;
