import React, { useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { Redirect, Route, useLocation } from "react-router-dom";

import AppTopbar from "./shared/Components/AppTopbar";
// import AppBreadcrumb from './shared/Components/AppBreadcrumb';
import AppInlineMenu from "./shared/Components/AppInlineMenu";
import AppFooter from "./shared/Components/AppFooter";
import AppMenu from "./shared/Components/AppMenu";
import AppConfig from "./shared/Components/AppConfig";
import AppRightMenu from "./shared/Components/AppRightMenu";
import { TowerService } from "./service/TowerService";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
// import { useSelector } from 'react-redux';

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "swagger-ui-react/swagger-ui.css";
import "./App.scss";

import PortfolioComponent from "./pages/Towers/PortfolioComponent";
import FaultReportsComponent from "./pages/Towers/FaultReportComponent";
import ConfigurationComponent from "./pages/Towers/ConfigurationComponent";
import TowersComponent from "./pages/Towers/TowersCompoment";
import SiteComponent from "./pages/Towers/SiteComponents";
import MenuSiteComponent from "./pages/Towers/MenuSiteComponent";
import MenuSiteProgressComponent from "./pages/Towers/MenuSiteProgressComponent";
import TowerInsightComponent from "./pages/Towers/TowerInsightComponent";
import InsightReportComponent from "./pages/Towers/CandedReports/InsiteReportComponent";
import TowerSummeryComponent from "./pages/Towers/TowerSummeryComponent";
import RecaptureForecast from "./pages/Towers/recaptureForecast";
import Tenancy from "./pages/Towers/TenancyComponent";
import SiteOverview from "./pages/Towers/siteOverview";
import SwaggerUIComponent from "./pages/Towers/swaggerUi";

export const RTLContext = React.createContext();
const staticmenu = [
    {
        // label: 'Towers', icon: 'pi pi-fw pi-home',
        items: [
            { label: "Portfolio Analysis", icon: "pi pi-fw pi-home", to: "/towers/portfolioanalysis" },
            { label: "Defect Information", icon: "pi pi-fw pi-exclamation-circle", to: "/towers/faultreports" },
            // { label: 'Inventory Details', icon: 'pi pi-fw pi-cog', to: '/towers/configuration' },
            { label: "Tenancy", icon: "pi pi-fw pi-home", to: "/towers/tenancy" },
            { label: "OnDemand Report", icon: "pi pi-fw pi-book", to: "/towers/towerinsight" },
            { label: "Recapture Forecaster", icon: "pi pi-fw pi-eye", to: "/towers/recaptureForecast" },
            { label: "Data Integration", icon: "fa-regular fa-file-lines", to: "/towers/swaggerui" },
            // { label: 'Sites Overview', icon: 'pi pi-fw pi-eye', to: '/towers/siteoverview' }
            // { label: 'M.A.C.D.', icon: 'pi pi-fw pi-table', to: '/towers/macd' },
        ],
    },
];
const App = () => {
    // const user = useSelector((state) => state.user);
    const [menu, setMenu] = useState(staticmenu);
    const [menuMode, setMenuMode] = useState("static");
    const [inlineMenuPosition, setInlineMenuPosition] = useState("bottom");
    const [desktopMenuActive, setDesktopMenuActive] = useState(true);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [colorMode, setColorMode] = useState("light");
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState("outlined");
    const [isRTL, setRTL] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [mobileTopbarActive, setMobileTopbarActive] = useState(false);
    const [menuTheme, setMenuTheme] = useState("light");
    const [topbarTheme, setTopbarTheme] = useState("blue");
    const [theme, setTheme] = useState("indigo");
    const [isInputBackgroundChanged, setIsInputBackgroundChanged] = useState(false);
    const [inlineMenuActive, setInlineMenuActive] = useState({});
    const [newThemeLoaded, setNewThemeLoaded] = useState(false); // eslint-disable-line
    const [searchActive, setSearchActive] = useState(false);
    const [active, setActive] = useState(false);
    const copyTooltipRef = useRef();
    let currentInlineMenuKey = useRef(null);
    const location = useLocation();
    const towerService = new TowerService();
    PrimeReact.ripple = true;

    let searchClick;
    let topbarItemClick;
    let menuClick;
    let inlineMenuClick;

    const createdynamicsitemenu = (data) => {
        let stateMap = {};
        let cityMap = {};
        let towerCount = 0;
        // console.log(data);
        data.forEach((item) => {
            if (stateMap[item.state]) stateMap[item.state] += item.towers.length;
            else stateMap[item.state] = item.towers.length;
            towerCount += item.towers.length;
        });

        const states = [...new Set(data.map((item) => item.state))];
        let stateitems = [];
        states.forEach((item) => {
            data.forEach((elm) => {
                if (elm.state === item) {
                    if (cityMap[elm.city]) cityMap[elm.city] += elm.towers.length;
                    else cityMap[elm.city] = elm.towers.length;
                }
            });
            // console.log(cityMap);
            let stateitem = {
                label: item,
                icon: "fa-solid fa-location-dot",
                badge: stateMap[item],
                badgeClassName: "p-badge-success color2-bg",
                items: [],
            };
            let cities = data.filter((elm) => elm.state === item);

            // console.log('Cities:',cities)
            cities.forEach((city) => {
                let cityitem = {
                    label: city.city,
                    icon: "fa-solid fa-city",
                    badge: cityMap[city.city],
                    badgeClassName: "p-badge-success color2-bg",
                    items: [],
                };
                let sites = data.filter((elm) => elm.city === city.city && elm.state === item);
                // console.log('Sites:',sites)
                sites.forEach((site) => {
                    // console.log(site)
                    site.towers.forEach((tower) => {
                        let siteitem = {
                            label: tower.towerid,
                            icon: "fa-solid fa-tower-cell",
                            to: "/towers-menu/" + tower.siteid + "/" + tower.lat + "/" + tower.lng,
                        };
                        cityitem.items.push(siteitem);
                    });
                });
                stateitem.items.push(cityitem);
            });
            stateitems.push(stateitem);
        });
        let dynamicmenu = {
            label: "Digitized Sites - " + towerCount,
            icon: "pi pi-fw pi-home",
            items: stateitems,
        };
        // console.log(dynamicmenu)
        return dynamicmenu;
    };

    const createdynamicsitemenuinprogress = (data) => {
        let stateMap = {};
        let cityMap = {};
        let towerCount = 0;
        // console.log(data);
        data.forEach((item) => {
            if (stateMap[item.state]) stateMap[item.state] += item.towers.length;
            else stateMap[item.state] = item.towers.length;
            towerCount += item.towers.length;
        });
        const states = [...new Set(data.map((item) => item.state))];
        let stateitems = [];
        states.forEach((item) => {
            data.forEach((elm) => {
                if (elm.state === item) {
                    if (cityMap[elm.city]) cityMap[elm.city] += elm.towers.length;
                    else cityMap[elm.city] = elm.towers.length;
                }
            });
            let stateitem = {
                label: item,
                icon: "fa-solid fa-location-dot",
                badge: stateMap[item],
                badgeClassName: "p-badge-success color2-bg",
                items: [],
            };
            // console.log(cityMap);
            let cities = data.filter((elm) => elm.state === item);
            // console.log('Cities:',cities)
            cities.forEach((city) => {
                let cityitem = {
                    label: city.city,
                    icon: "fa-solid fa-city",
                    badge: cityMap[city.city],
                    badgeClassName: "p-badge-success color2-bg",
                    items: [],
                };
                let sites = data.filter((elm) => elm.city === city.city && elm.state === item);
                // console.log('Sites:',sites)
                sites.forEach((site) => {
                    // console.log(site)
                    site.towers.forEach((tower) => {
                        let siteitem = {
                            label: tower.towerid,
                            icon: "fa-solid fa-tower-cell",
                            to: "/towers-progress-menu/" + tower.siteid + "/" + tower.region + "/" + tower.invcode,
                        };
                        cityitem.items.push(siteitem);
                    });
                });
                stateitem.items.push(cityitem);
            });
            stateitems.push(stateitem);
        });
        let dynamicmenu = {
            label: "InProgress Sites - " + towerCount,
            icon: "pi pi-fw pi-home",
            items: stateitems,
        };
        // console.log(dynamicmenu)
        return dynamicmenu;
    };
    useEffect(() => {
        towerService.getStateWiseInProgressMenu().then((data) => {
            // console.log(data)
            if (data.length) setMenu((prevState) => [...prevState, createdynamicsitemenuinprogress(data)]);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        towerService.getStateWiseMenu().then((data) => {
            setMenu((prevState) => [...prevState, createdynamicsitemenu(data)]);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // const routes = [
    //     { parent: 'Towers', label: 'Portfolio Analysis' },
    //     { parent: 'Towers', label: 'Fault Reports' },
    //     { parent: 'Towers', label: 'Configuration' },
    //     { parent: 'Towers', label: 'Accounting' },
    //     { parent: 'Towers', label: 'Tower Insight' },
    // ];

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (menuMode === "overlay") {
            hideOverlayMenu();
        }
        if (menuMode === "static") {
            setDesktopMenuActive(true);
        }
    }, [menuMode]);

    useEffect(() => {
        onColorModeChange(colorMode);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onMenuThemeChange = (theme) => {
        setMenuTheme(theme);
    };

    const onTopbarThemeChange = (theme) => {
        setTopbarTheme(theme);
    };

    // useEffect(() => {
    //     const appLogoLink = document.getElementById('app-logo');
    //     if(appLogoLink){
    //         if (topbarTheme === 'white' || topbarTheme === 'yellow' || topbarTheme === 'amber' || topbarTheme === 'orange' || topbarTheme === 'lime') {
    //             appLogoLink.src = 'assets/layout/images/logo-dark.png';
    //         }
    //         else {
    //             appLogoLink.src = 'assets/layout/images/logo-light.png';
    //         }
    //     }
    // }, [topbarTheme])

    const onThemeChange = (theme) => {
        setTheme(theme);
        const themeLink = document.getElementById("theme-css");
        const themeHref = "assets/theme/" + theme + "/theme-" + colorMode + ".css";
        replaceLink(themeLink, themeHref);
    };

    const onColorModeChange = (mode) => {
        setColorMode(mode);
        setIsInputBackgroundChanged(true);

        if (isInputBackgroundChanged) {
            if (mode === "dark") {
                setInputStyle("filled");
            } else {
                setInputStyle("outlined");
            }
        }

        if (mode === "dark") {
            setMenuTheme("dark");
            setTopbarTheme("dark");
        } else {
            setMenuTheme("light");
            setTopbarTheme("blue");
        }

        const layoutLink = document.getElementById("layout-css");
        const layoutHref = process.env.PUBLIC_URL + "/assets/layout/css/layout-" + mode + ".css";
        replaceLink(layoutLink, layoutHref);

        const themeLink = document.getElementById("theme-css");
        const urlTokens = themeLink.getAttribute("href").split("/");
        urlTokens[urlTokens.length - 1] = "theme-" + mode + ".css";
        const newURL = urlTokens.join("/");

        replaceLink(themeLink, newURL, () => {
            setNewThemeLoaded(true);
        });
    };

    const replaceLink = (linkElement, href, callback) => {
        // console.log('======replace link called =======>', linkElement, href)
        if (isIE()) {
            linkElement.setAttribute("href", href);

            if (callback) {
                callback();
            }
        } else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);

                if (callback) {
                    callback();
                }
            });
        }
    };

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onInlineMenuPositionChange = (mode) => {
        setInlineMenuPosition(mode);
    };

    const onMenuModeChange = (mode) => {
        setMenuMode(mode);
    };

    const onRTLChange = () => {
        setRTL((prevState) => !prevState);
    };

    const onMenuClick = (event) => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isDesktop()) setDesktopMenuActive((prevState) => !prevState);
        else setMobileMenuActive((prevState) => !prevState);

        event.preventDefault();
    };

    const activeStateChange = (state) => {
        setActive(state);
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        if (activeTopbarItem === event.item) setActiveTopbarItem(null);
        else {
            setActiveTopbarItem(event.item);
        }

        event.originalEvent.preventDefault();
        if (event.item === "setting") setActive(true);
    };

    const onSearch = (event) => {
        searchClick = true;
        setSearchActive(event);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items && (menuMode === "overlay" || !isDesktop())) {
            hideOverlayMenu();
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const onRootMenuItemClick = (event) => {
        setMenuActive((prevState) => !prevState);
    };

    const onRightMenuButtonClick = () => {
        setRightMenuActive((prevState) => !prevState);
    };

    const onMobileTopbarButtonClick = (event) => {
        setMobileTopbarActive((prevState) => !prevState);
        event.preventDefault();
    };

    const onDocumentClick = (event) => {
        if (!searchClick && event.target.localName !== "input") {
            setSearchActive(false);
        }

        if (!topbarItemClick) {
            setActiveTopbarItem(null);
        }

        if (!menuClick && (menuMode === "overlay" || !isDesktop())) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
            }
            hideOverlayMenu();
        }

        if (inlineMenuActive[currentInlineMenuKey.current] && !inlineMenuClick) {
            let menuKeys = { ...inlineMenuActive };
            menuKeys[currentInlineMenuKey.current] = false;
            setInlineMenuActive(menuKeys);
        }

        if (!menuClick && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }

        searchClick = false;
        topbarItemClick = false;
        inlineMenuClick = false;
        menuClick = false;
    };

    const hideOverlayMenu = () => {
        setMobileMenuActive(false);
        setDesktopMenuActive(false);
    };

    const isDesktop = () => {
        return window.innerWidth > 1024;
    };

    const isHorizontal = () => {
        return menuMode === "horizontal";
    };

    const isSlim = () => {
        return menuMode === "slim";
    };

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };

    const onInlineMenuClick = (e, key) => {
        let menuKeys = { ...inlineMenuActive };
        if (key !== currentInlineMenuKey.current && currentInlineMenuKey.current) {
            menuKeys[currentInlineMenuKey.current] = false;
        }

        menuKeys[key] = !menuKeys[key];
        setInlineMenuActive(menuKeys);
        currentInlineMenuKey.current = key;
        inlineMenuClick = true;
    };

    const layoutContainerClassName = classNames("layout-wrapper ", "layout-menu-" + menuTheme + " layout-topbar-" + topbarTheme, {
        "layout-menu-static": menuMode === "static",
        "layout-menu-overlay": menuMode === "overlay",
        "layout-menu-slim": menuMode === "slim",
        "layout-menu-horizontal": menuMode === "horizontal",
        "layout-menu-active": desktopMenuActive,
        "layout-menu-mobile-active": mobileMenuActive,
        "layout-topbar-mobile-active": mobileTopbarActive,
        "layout-rightmenu-active": rightMenuActive,
        "p-input-filled": inputStyle === "filled",
        "p-ripple-disabled": !ripple,
        "layout-rtl": isRTL,
    });
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const serviceId = params.get("serviceId");

    if (token) sessionStorage.setItem("token", token);
    if (serviceId) sessionStorage.setItem("serviceId", serviceId);

    return sessionStorage.getItem("token") ? (
        <RTLContext.Provider value={isRTL}>
            <div className={layoutContainerClassName} onClick={onDocumentClick}>
                <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

                <AppTopbar
                    horizontal={isHorizontal()}
                    activeTopbarItem={activeTopbarItem}
                    onMenuButtonClick={onMenuButtonClick}
                    onTopbarItemClick={onTopbarItemClick}
                    onRightMenuButtonClick={onRightMenuButtonClick}
                    onMobileTopbarButtonClick={onMobileTopbarButtonClick}
                    mobileTopbarActive={mobileTopbarActive}
                    searchActive={searchActive}
                    onSearch={onSearch}
                />

                <div className="menu-wrapper" onClick={onMenuClick}>
                    <div className="layout-menu-container">
                        {(inlineMenuPosition === "top" || inlineMenuPosition === "both") && <AppInlineMenu menuKey="top" inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />}
                        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} onRootMenuItemClick={onRootMenuItemClick} menuMode={menuMode} active={menuActive} />
                        {/* {(inlineMenuPosition === 'bottom' || inlineMenuPosition === 'both') && <AppInlineMenu menuKey='bottom' inlineMenuActive={inlineMenuActive} onInlineMenuClick={onInlineMenuClick} horizontal={isHorizontal()} menuMode={menuMode} />} */}
                    </div>
                </div>

                <div className="layout-main">
                    {/* <AppBreadcrumb routes={routes} /> */}

                    <div className="layout-content">
                        <Route exact path="/" component={SiteOverview} />
                        <Route path="/towers/portfolioanalysis" component={PortfolioComponent} />
                        <Route path="/towers/faultreports" component={FaultReportsComponent} />
                        <Route path="/towers/configuration" component={ConfigurationComponent} />
                        <Route path="/towers/tenancy" component={Tenancy} />
                        <Route exact path="/towers" component={TowersComponent} />
                        <Route path="/towers/:state/:city/:id" component={SiteComponent} />
                        <Route path="/towers-menu/:towerid/:lat/:lng" component={MenuSiteComponent} />
                        <Route path="/towers-progress-menu/:towerid/:region/:invcode" component={MenuSiteProgressComponent} />
                        <Route exact path="/towers/towerinsight" component={TowerInsightComponent} />
                        <Route exact path="/tower-report/:condition" component={InsightReportComponent} />
                        <Route path="/tower-report/:state/:condition" component={TowerSummeryComponent} />
                        <Route path="/towers/recaptureForecast" component={RecaptureForecast} />
                        <Route path="/towers/siteoverview" component={SiteOverview} />
                        <Route path="/towers/swaggerui" component={SwaggerUIComponent} />
                    </div>

                    <AppFooter colorMode={colorMode} />
                </div>

                <AppConfig
                    inputStyle={inputStyle}
                    onInputStyleChange={onInputStyleChange}
                    rippleEffect={ripple}
                    onRippleEffect={onRipple}
                    menuMode={menuMode}
                    onMenuModeChange={onMenuModeChange}
                    inlineMenuPosition={inlineMenuPosition}
                    onInlineMenuPositionChange={onInlineMenuPositionChange}
                    colorMode={colorMode}
                    onColorModeChange={onColorModeChange}
                    menuTheme={menuTheme}
                    onMenuThemeChange={onMenuThemeChange}
                    topbarTheme={topbarTheme}
                    onTopbarThemeChange={onTopbarThemeChange}
                    theme={theme}
                    onThemeChange={onThemeChange}
                    isRTL={isRTL}
                    onRTLChange={onRTLChange}
                    active={active}
                    activeStateChange={activeStateChange}
                />

                <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuButtonClick={onRightMenuButtonClick} />

                {mobileMenuActive && <div className="layout-mask modal-in"></div>}
            </div>
        </RTLContext.Provider>
    ) : (
        <Redirect exact from="*" to={"/login"} />
    );
};

export default App;
