import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import { TowerService } from '../../service/TowerService';
import TowerTransitionComponent from './TowerTransitionComponent';
import { colorPalettes } from '../../constants';

const barChartOption = {
	maintainAspectRatio: false,
	aspectRatio: 1.5,
	barThickness: 20,
	plugins: {
		legend: {
			labels: {
				font: {
					size: 14
				},
				weight: 'bold',
				color: colorPalettes.bar_x_axis_text,
			},
		}
	},
	scales: {
		x: { ticks: { color: colorPalettes.bar_x_axis_text }},
		y: { ticks: { precision: 0, color: colorPalettes.bar_x_axis_text}},
	},
};

class FaultReportsComponent extends Component {
	constructor(props) {
		super(props);
		this.towerService = new TowerService();

		this.state = {
			totalSites: null,
			towerStracturalDefect: [],
			siteStracturalDefect: [],
			rustSeverity: [],
			basicFirst: 0,
			basicRows: 10,
			totalSitesWithDefects: 0,
			totalSiteWithRust: 0,
			totalTowerDefects: 0,
			totalSiteDefects: 0
		};
	}
	componentDidMount = () => {
		Promise.all([this.towerService.getTotalSitesWithDefects(), this.towerService.getAllDefectsInstancesAndSites(),
		this.towerService.getRustSeverityData()])
			.then((resp) => {
				let defectsDetails = resp[0];
				let allDefects = resp[1];
				let rustData = resp[2];
				let stateObj = {};
				if (defectsDetails && defectsDetails.length && defectsDetails[0].totalSites) {
					this.sitesWithDefects = defectsDetails[0].sitesWithDefects.length;
					this.noDefectSites = defectsDetails[0].totalSites.length - defectsDetails[0].sitesWithDefects.length;
					this.defecthartData = {
						labels: ['Sites With No Defects', 'Sites With Defects'],
						datasets: [
							{
								data: [this.noDefectSites, this.sitesWithDefects],
								backgroundColor: [colorPalettes.color1, colorPalettes.color5],
							},
						],
					};
				}

				let defectsTypesNm = [], instancesArr = [], sitesArr = [], towerDefectTypes = [], sitesDefectTypes = [];
				if (allDefects.length) {
					allDefects = allDefects[0];
					if (allDefects.towerDefectsCnt) {
						Object.keys(allDefects.towerDefects).forEach(t => {
							if (allDefects.towerDefects[t].sites) {
								let res = t.replace(/[A-Z]/g, ' $&').trim();
								let str = res.charAt(0).toUpperCase() + res.slice(1);
								defectsTypesNm.push(str);
								instancesArr.push(allDefects.towerDefects[t].instances);
								sitesArr.push(allDefects.towerDefects[t].sites);
								let obj = Object.assign({}, allDefects.towerDefects[t]);
								obj.dType = str;
								towerDefectTypes.push(obj);
							}
						});
					}
					if (allDefects.siteDefectsCnt) {
						Object.keys(allDefects.siteDefects).forEach(t => {
							if (allDefects.siteDefects[t].sites) {
								let res = t.replace(/[A-Z]/g, ' $&').trim();
								let str = res.charAt(0).toUpperCase() + res.slice(1);
								defectsTypesNm.push(str);
								instancesArr.push(allDefects.siteDefects[t].instances);
								sitesArr.push(allDefects.siteDefects[t].sites);
								let obj = Object.assign({}, allDefects.siteDefects[t]);
								obj.dType = str;
								sitesDefectTypes.push(obj);
							}
						});
					}

					stateObj.totalSiteWithRust = allDefects.rustDefectCnt || 0;
					stateObj.totalTowerDefects = allDefects.towerDefectsCnt || 0;
					stateObj.totalSiteDefects = allDefects.siteDefectsCnt || 0;
				}


				let defectTypesBarChart = {
					labels: defectsTypesNm,
					datasets: [
						{
							label: 'Instances',
							data: instancesArr,
							fill: false,
							backgroundColor: colorPalettes.color2,
							borderColor: colorPalettes.color2
						},
						{
							label: 'Sites',
							data: sitesArr,
							fill: false,
							backgroundColor: colorPalettes.color4,
							borderColor: colorPalettes.color4,
						},

					]
				}

				stateObj.totalSites = defectsDetails[0].totalSites.length;
				stateObj.defectsTypesNm = defectsTypesNm;
				stateObj.defectTypesBarChart = defectTypesBarChart;
				stateObj.towerStracturalDefect = towerDefectTypes;
				stateObj.siteStracturalDefect = sitesDefectTypes;
				stateObj.rustSeverity = rustData;

				this.setState(stateObj);
			})
			.catch(e => {
				console.error(e);
			})
	};

	onPieClick = (evt, element) => {
		if (element.length) {
			let query = { "towerDetails.totalSiteDefects": 0, "towerDetails.totalTowerDefects": 0 };
			if (element[0].index === 1) query = { "$or": [{ "towerDetails.totalSiteDefects": { "$gt": 0 } }, { "towerDetails.totalTowerDefects": { "$gt": 0 } }] };

			this.towerService.getTowerQuery(query).then(data => {
				this.setState({
					detailedViewClick: true,
					details: data
				})
			});
		}
	}

	handleBack = () => {
		this.setState({
			detailedViewClick: false,
			details: []
		})
	}

	getDefectTypeBarChartOpt = () => {
		let opt = Object.assign({}, barChartOption);
		opt.onClick = this.onDefectBarClick.bind(this);
		return opt;
	}

	onDefectBarClick = (evt, element) => {
		if (element.length > 0) {
			let allDefects = [...this.state.towerStracturalDefect, ...this.state.siteStracturalDefect];
			let defectNm = this.state.defectsTypesNm[element[0].index];
			let filteredDefectType = allDefects.find(d => d.dType === defectNm);
			if (filteredDefectType && filteredDefectType.towerIds && filteredDefectType.towerIds.length) {
				let query = { "customerSiteid": { "$in": filteredDefectType.towerIds } };
				this.towerService.getTowerQuery(query).then(data => {
					this.setState({
						detailedViewClick: true,
						details: data
					})
				});
			}
		}
	}

	onDefectTypeClick = (e) => {
		if (e.data && e.data.towerIds && e.data.towerIds.length) {
			let query = { "customerSiteid": { "$in": e.data.towerIds } };
			this.towerService.getTowerQuery(query).then(data => {
				this.setState({
					detailedViewClick: true,
					details: data
				})
			});
		}
	}

	handleSiteWithRust = () => {
		if (this.state.totalSiteWithRust) {
			let query = { "towerDefects.rustArea": { "$exists": true, "$ne": [] } };
			this.towerService.getTowerQuery(query).then(data => {
				this.setState({
					detailedViewClick: true,
					details: data
				})
			});
		}
	}

	handleCardClick = (id, value) => {
		if (value) {
			let query = {};
			if (id === "rust") query = { "towerDefects.rustArea": { "$exists": true, "$ne": [] } };
			else if (id === 'tower') query = { "towerDetails.totalTowerDefects": { "$gt": 0 } };
			else if (id === 'site') query = { "towerDetails.totalSiteDefects": { "$gt": 0 } }
			this.towerService.getTowerQuery(query).then(data => {
				this.setState({
					detailedViewClick: true,
					details: data
				})
			});
		}
	}

	render = () => {
		return (
			this.state.detailedViewClick ? <TowerTransitionComponent towers={this.state.details} hadelBack={this.handleBack} /> :
				<div>
					<div className='grid' style={{marginBottom: "1rem"}}>
						{
							this.defecthartData ?<div className='col-12 lg:col-4 sm:col-12 xs:col-12'>
							<div className="fault-widgets">
								<div className='grid'>
									<div className='col-12 lg:col-11'>
										{this.state.totalSites ? <h5 className=' text-2xl' style={{ marginBottom: 0 }}>{"Sites Defect Status"} </h5> : null}
									</div>
								</div>
								<div className="lg:col-offset-2 lg:col-7 xs:col-offset-3  xs:col-5 sm:col-offset-3 sm:col-4 ">
									<Chart
										style={{ position: 'relative' }}
										type='pie'
										data={this.defecthartData}
										options={{ plugins: {
											legend: {
												labels: {
													font: {
														size: 14
													},
													usePointStyle: true,
													pointStyle: 'circle',
													color: "#495057",
												},
												
											},
										},onClick: this.onPieClick.bind(this) }}
									/>
								</div>
							</div>
							</div>
						: null}
						{
							this.state.defectsTypesNm && this.state.defectsTypesNm.length ?
								<div className='col-12 lg:col-8'>
									<div className="fault-widgets">
										<div className='grid'>
											<div className='col-12 lg:col-11'>
												<h5 className=' text-2xl' style={{ marginBottom: 0 }}>Defect Distribution</h5>
											</div>
										</div>
										<div className="lg:col-12" style={{ overflowX: "auto" }}>
											<Chart
												style={{ position: 'relative', width: "55rem" }}
												type='bar'
												data={this.state.defectTypesBarChart}
												options={this.getDefectTypeBarChartOpt()}
											/>
										</div>
									</div>
								</div>
								: null
						}
					</div>


					<div className='grid'>
						<div className='col-12 md:col-4 lg:col-4'>
							<div className='card color5-bg' style={{cursor: "pointer"}} onClick={() => this.handleCardClick("rust", this.state.totalSiteWithRust)}>
								<div className='flex justify-content-between '>
									<div>
										<h5 className='mb-3 text-2xl'>Total Site with Rust</h5>
										<div className='text-900 font-medium text-2xl color5-bg'>
											{this.state.totalSiteWithRust}
										</div>
									</div>
									<div
										className='flex align-items-center justify-content-center bg-white-alpha-90 border-round'
										style={{ width: '2.5rem', height: '2.5rem' }}
									>
										<i className='pi pi-exclamation-triangle text-orange-500 text-xl' />
									</div>
								</div>
							</div>
							<div className='card'>
								<DataTable value={this.state.rustSeverity} showGridlines scrollHeight="20rem"
									onRowClick={(e) => this.onDefectTypeClick(e)} responsiveLayout="scroll">
									<Column headerClassName='color1-bg' bodyClassName="color2-bg" header="Rust Severity Level" field="_id"></Column>
									<Column headerClassName='color1-bg' bodyClassName="color2-bg" header="Instances" field="instances"></Column>
									<Column headerClassName='color1-bg' bodyClassName="color2-bg" header="Sites" field="sites"></Column>
								</DataTable>
							</div>
						</div>
						<div className='col-12 md:col-4 lg:col-4'>
							<div className='card color5-bg' style={{cursor: "pointer"}} onClick={() => this.handleCardClick("tower", this.state.totalTowerDefects)}>
								<div className='flex justify-content-between'>
									<div>
										<h5 className='mb-3 text-2xl'>Tower Structure Defects</h5>
										<div className='text-900 font-medium text-2xl color5-bg'>
											{this.state.totalTowerDefects}
										</div>
									</div>
									<div
										className='flex align-items-center justify-content-center bg-white-alpha-90 border-round'
										style={{ width: '2.5rem', height: '2.5rem' }}
									>
										<i className='pi pi-exclamation-triangle text-orange-500 text-xl' />
									</div>
								</div>
							</div>
							<div className='card'>
								<DataTable value={this.state.towerStracturalDefect} showGridlines scrollHeight="20rem" 
									onRowClick={(e) => this.onDefectTypeClick(e)} responsiveLayout="scroll">
									<Column headerClassName='color1-bg' bodyClassName="color2-bg" header="Defect Type" field="dType"></Column>
									<Column headerClassName='color1-bg' bodyClassName="color2-bg" header="Instances" style={{ width: '1rem' }} field="instances"></Column>
									<Column headerClassName='color1-bg' bodyClassName="color2-bg" header="Sites" style={{ width: '1rem' }} field="sites"></Column>
								</DataTable>

							</div>
						</div>
						<div className='col-12 md:col-4 lg:col-4'>
							<div className='card color5-bg' style={{cursor: "pointer"}} onClick={() => this.handleCardClick("site", this.state.totalSiteDefects)}>
								<div className='flex justify-content-between'>
									<div>
										<h5 className='mb-3 text-2xl'>Site Defects</h5>
										<div className='text-900 font-medium text-2xl color5-bg'>
											{this.state.totalSiteDefects}
										</div>
									</div>
									<div
										className='flex align-items-center justify-content-center bg-white-alpha-90 border-round'
										style={{ width: '2.5rem', height: '2.5rem' }}
									>
										<i className='pi pi-exclamation-triangle text-orange-500 text-xl' />
									</div>
								</div>
							</div>
							<div className='card'>
								<DataTable value={this.state.siteStracturalDefect} showGridlines scrollHeight="20rem"
									onRowClick={(e) => this.onDefectTypeClick(e)} responsiveLayout="scroll">
									<Column className='color1-bg' header="Defect Type" field="dType"></Column>
									<Column className='color1-bg' header="Instances" field="instances"></Column>
									<Column className='color1-bg' header="Sites" field="sites"></Column>
								</DataTable>
							</div>
						</div>
					</div>
				</div>
		);
	};
}

export default FaultReportsComponent;
