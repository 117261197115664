import React, { Component } from "react";
import { Galleria } from 'primereact/galleria';
import './GalleriaAdvancedDemo.css';

export default class EquipmentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            activeIndex: 0,
        };
        this.responsiveOptions = [
            {
                breakpoint: "1024px",
                numVisible: 5,
            },
            {
                breakpoint: "960px",
                numVisible: 4,
            },
            {
                breakpoint: "768px",
                numVisible: 3,
            },
            {
                breakpoint: "560px",
                numVisible: 1,
            },
        ];
        this.itemTemplate = this.itemTemplate.bind(this);
        this.thumbnailTemplate = this.thumbnailTemplate.bind(this);
    }

    componentDidMount() {
        let image_url = process.env.REACT_APP_IMAGE_STATIC_URL;
        let images = [];
        if(Object.keys(this.props.tower.siteEquipment).length !== 0) {
            let site_equipment = this.props.tower.siteEquipment;
            
            if(site_equipment.hasOwnProperty('dieselGenerator')) {
                if(site_equipment.dieselGenerator.dgImageName) {
                    images.push({
                        "itemImageSrc": image_url+site_equipment.dieselGenerator.dgImageName,
                        "thumbnailImageSrc": image_url+site_equipment.dieselGenerator.dgImageName,
                        "alt": "Diesel Generator",
                        "title": "Diesel Generator"
                    })
                }
            }
            if(site_equipment.hasOwnProperty('batteryBank')) {
                if(site_equipment.batteryBank.bbImageName) {
                    images.push({
                        "itemImageSrc": image_url+site_equipment.batteryBank.bbImageName,
                        "thumbnailImageSrc": image_url+site_equipment.batteryBank.bbImageName,
                        "alt": "Battery Bank",
                        "title": "Battery Bank"
                    })
                }
            }
        }
        this.setState({images})
    }
    onCardClick = (item) => {
        
        if(item === 'dieselGenerator') {
            let index = this.state.images.findIndex(elm => elm.title === 'Diesel Generator');
            
            if(index > -1) {
                this.setState({activeIndex: index});
            } else {
                this.setState({activeIndex: 0});
            }
        }
        
    }
    itemTemplate(item) {
        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%', height: '400px' }} />
    }

    thumbnailTemplate(item) {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ width: '100px', height: '80px' }} />
    }

    render = () => {
        return (
            <div className="grid">
                <div className="col-12 lg:col-6">
                    <div className="grid">
                        {
                            this.props.tower.siteEquipment.hasOwnProperty('dieselGenerator') ? (
                                this.props.tower.siteEquipment.dieselGenerator.count > 0 ? (
                                <div className="col-12 md:col-6">
                                    <div className="card" style={{cursor: 'pointer'}} onClick={this.onCardClick.bind(this, 'dieselGenerator')}>
                                        <span className="block text-blue-600 font-medium mb-3 text-2xl">
                                            Diesel Generator 
                                        </span>
                                        <hr/>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">
                                            Count: <span className="text-color" style={{float: 'right'}}>
                                                {this.props.tower.siteEquipment.dieselGenerator.count}
                                            </span>
                                        </p>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">Capacity: <span className="text-color" style={{float: 'right'}}>
                                            {
                                                this.props.tower.siteEquipment.dieselGenerator.capacity ? this.props.tower.siteEquipment.dieselGenerator.capacity : "Unspecified"
                                            }    
                                        </span></p>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">Make: <span className="text-color" style={{float: 'right'}}>
                                            {
                                                this.props.tower.siteEquipment.dieselGenerator.make ? this.props.tower.siteEquipment.dieselGenerator.make : "Unspecified"
                                            }    
                                        </span></p>
                                        <p className="mb-0 font-medium text-blue-600 text-xl">Model: <span className="text-color" style={{float: 'right'}}>
                                            {
                                                this.props.tower.siteEquipment.dieselGenerator.model ? this.props.tower.siteEquipment.dieselGenerator.model : "Unspecified"
                                            }    
                                        </span></p>
                                    </div>
                                </div>) : null
                            ) : null
                        }
                        {
                            this.props.tower.siteEquipment.hasOwnProperty('transformer') ? (
                                this.props.tower.siteEquipment.transformer.present === "Yes" ?
                                (<div className="col-12 md:col-6">
                                    <div className="card">
                                        <span className="block text-blue-600 font-medium text-2xl mb-3">
                                            Transformer
                                        </span>
                                        <hr/>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">Present: 
                                            <span className="text-color" style={{float: 'right'}}>
                                                Yes
                                            </span>
                                        </p>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">Make: 
                                            <span className="text-color" style={{float: 'right'}}>
                                                {
                                                    this.props.tower.siteEquipment.transformer.make ? this.props.tower.siteEquipment.transformer.make : "Unspecified"
                                                }
                                            </span>
                                        </p>
                                        <p className="mb-0 font-medium text-blue-600 text-xl">
                                            Model: <span className="text-color" style={{float: 'right'}}>
                                                {
                                                    this.props.tower.siteEquipment.transformer.model ? this.props.tower.siteEquipment.transformer.model : "Unspecified"
                                                }
                                            </span>
                                        </p>
                                    </div>
                                </div>) : null
                            ) : null
                        }
                        {
                            this.props.tower.siteEquipment.hasOwnProperty('precisionAc') ? (
                                this.props.tower.siteEquipment.precisionAc.count > 0 ?
                                (<div className="col-12 md:col-6">
                                    <div className="card">
                                        <span className="block text-blue-600 font-medium text-2xl mb-3">
                                            Precision Ac
                                        </span>
                                        <hr/>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">
                                            Capacity: <span className="text-color" style={{float: 'right'}}>
                                                {this.props.tower.siteEquipment.precisionAc.capacity}
                                            </span>
                                        </p>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">
                                            Make: <span className="text-color" style={{float: 'right'}}>{this.props.tower.siteEquipment.precisionAc.make ? this.props.tower.siteEquipment.precisionAc.make : "Unspecified"}</span>
                                        </p>
                                        <p className="mb-0 font-medium text-blue-600 text-xl">
                                            Model: <span className="text-color" style={{float: 'right'}}>{this.props.tower.siteEquipment.precisionAc.model ? this.props.tower.siteEquipment.precisionAc.model : "Unspecified"}</span>
                                        </p>
                                    </div>
                                </div>) : null 
                            ) : null
                        }
                        {
                            this.props.tower.siteEquipment.hasOwnProperty('splitAC') ? ( 
                                this.props.tower.siteEquipment.splitAC.count > 0 ?
                                (<div className="col-12 md:col-6">
                                    <div className="card">
                                        <span className="block text-blue-600 font-medium text-2xl mb-3">Split AC</span>
                                        <hr/>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">Capacity: <span className="text-color" style={{float: 'right'}}>
                                            {this.props.tower.siteEquipment.splitAC.capacity ? this.props.tower.siteEquipment.splitAC.capacity : "Unspecified" }</span></p>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">Make: <span className="text-color" style={{float: 'right'}}>
                                            {this.props.tower.siteEquipment.splitAC.make ? this.props.tower.siteEquipment.splitAC.make : "Unspecified" }</span></p>
                                        <p className="mb-0 font-medium text-blue-600 text-xl">Model: <span className="text-color" style={{float: 'right'}}>
                                            {this.props.tower.siteEquipment.splitAC.model ? this.props.tower.siteEquipment.splitAC.model : "Unspecified" }</span></p>
                                    </div>
                                </div> ) : null
                            ) : null
                        }
                        {
                            this.props.tower.siteEquipment.hasOwnProperty('batteryBank') ? (
                                this.props.tower.siteEquipment.batteryBank.present === "Yes" ?
                                (<div className="col-12 md:col-6">
                                    <div className="card">
                                        <span className="block text-blue-600 font-medium text-2xl mb-3">Battery Bank</span>
                                        <hr/>
                                        <p className="mb-2 font-medium text-blue-600 text-xl">
                                            Capacity: <span className="text-color" style={{float: 'right'}}>{this.props.tower.siteEquipment.batteryBank.batteryBankCapacity ? this.props.tower.siteEquipment.batteryBank.batteryBankCapacity : "Unspecified"}</span>
                                        </p>
                                        <p className="mb-0 font-medium text-blue-600 text-xl">
                                            Description: <span className="text-color" style={{float: 'right'}}>{this.props.tower.siteEquipment.batteryBank.bbImageDescription ? this.props.tower.siteEquipment.batteryBank.bbImageDescription : "Unspecified"}</span>
                                        </p>
                                    </div>
                                </div> ) : null
                            ) : null
                        }
                        {
                            this.props.tower.siteEquipment.hasOwnProperty('ebMeter') ? (
                                this.props.tower.siteEquipment.ebMeter === 'Yes' ? (
                                    <div className="col-12 md:col-6">
                                        <div className="card">
                                            <span className="block text-blue-600 font-medium text-2xl mb-3">EB Meter: <span className="text-green-500">Yes</span></span>
                                        </div>
                                    </div>
                                ) : null
                            ) : null
                        }
                        {
                            this.props.tower.siteEquipment.hasOwnProperty('fireExtinguisher') ? (
                                this.props.tower.siteEquipment.fireExtinguisher === 'Yes' ? (
                                    <div className="col-12 md:col-6">
                                        <div className="card">
                                            <span className="block text-blue-600 font-medium text-2xl mb-3">Fire Extinguisher: <span className="text-green-500">Yes</span></span>
                                        </div>
                                    </div>
                                ) : null
                            ) : null
                        }
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card">
                        {
                            this.state.images && this.state.images.length > 0 ? (
                                <Galleria 
                                    value={this.state.images} 
                                    responsiveOptions={this.responsiveOptions} 
                                    activeIndex={this.state.activeIndex}
                                    onItemChange={(e) => this.setState({ activeIndex: e.index })}
                                    numVisible={2} 
                                    style={{maxWidth: '100%'}}
                                    item={this.itemTemplate} 
                                    thumbnail={this.thumbnailTemplate} />
                            ) : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}