import { httpService } from "./httpService";
import { httpConstants } from "../constants";

function getTenantServiceUrl() {
    return process.env.REACT_APP_TENANT_SERVICE_URL;
}

async function getPermissions() {
    let url = getTenantServiceUrl() + httpConstants.API_END_POINT.GET_USER_PERMISSIONS;
    return httpService(httpConstants.METHOD_TYPE.POST, null, url)
        .then(
            response => {
                if (!response.success || response.responseCode !== 200 || !response.responseData || response.responseData.length === 0)
                    return Promise.reject();
                return Promise.resolve(response.responseData);
            }
        ).catch(function (err) {
            return Promise.reject(err);
        });
}

export default {
    getPermissions
}