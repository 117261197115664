import axios from "axios";
const weatherUrl = process.env.REACT_APP_WEATHER_API_URL;
const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
export class TowerService {
    constructor() {
        this.url = "https://wg8cp34rch.execute-api.us-east-1.amazonaws.com/apigwdocdb/" + sessionStorage.getItem("serviceId") + "/";
        if (sessionStorage.getItem("states") !== undefined && sessionStorage.getItem("cities") !== undefined && sessionStorage.getItem("zipcodes") !== undefined) {
            this.states = JSON.parse(sessionStorage.getItem("states")) || [];
            this.cities = JSON.parse(sessionStorage.getItem("cities")) || [];
            this.zipcodes = JSON.parse(sessionStorage.getItem("zipcodes")) || [];
            this.queryArray = [];
            if (this.states.length > 0) {
                this.queryArray.push({ "siteDetails.location.state": { $in: this.states } });
            }
            if (this.cities.length > 0) {
                this.queryArray.push({ "siteDetails.location.city": { $in: this.cities } });
            }
            if (this.zipcodes.length > 0) {
                this.queryArray.push({ "siteDetails.location.zipcode": { $in: this.zipcodes } });
            }
        }
    }

    getDigitizationStatus = async (serviceID) => {
        let payload = {
            filter: {
                serviceId: serviceID,
            },
        };
        const res = await axios.post(process.env.REACT_APP_DOCDB_SERVICE_URL + "/nextqore/nq-towers-counts", payload);
        return res.data;
    };

    getAllTowers = async () => {
        let query = {
            filter: {},
        };
        if (this.queryArray.length > 0) {
            query.filter = {
                $and: [...this.queryArray, {}],
            };
        } else {
            query.filter = {
                $and: [{}],
            };
        }

        // let query = { "filter": q };
        const res = await axios.post(this.url + "nq-towers", query);
        return res.data;
    };

    getStateAllTowers = async (state) => {
        const res = await axios.post(this.url + "nq-towers", { filter: { "siteDetails.location.state": state } });
        return res.data;
    };

    getOneTower = async (id) => {
        const res = await axios.post(this.url + "nq-towers", { filter: { customerSiteid: id } });
        return res.data;
    };

    getMyxOneTower = async (id) => {
        const res = await axios.post(this.url + "nq-towers", { filter: { myxTowerid: id } });
        return res.data;
    };
    getDegitizeAnalysis = async () => {
        const res = await axios.post(this.url + "nq-control-tasks", {});
        return res.data;
    };

    getCurrentWeather = async (position) => {
        const { lat, lng } = position;
        const res = await axios.get(weatherUrl + "?lat=" + lat + "&lon=" + lng + "&appid=" + apiKey + "&units=Metric");
        return res.data;
    };

    getTowerTypesCount = async () => {
        let groupQ = { $group: { _id: "$towerDetails.towerConfig.towerType", count: { $sum: 1 } } };
        let aggregate = [];
        if (this.queryArray.length > 0) {
            aggregate.push({
                $match: {
                    $and: [...this.queryArray],
                },
            });
            aggregate.push(groupQ);
        } else {
            aggregate.push(groupQ);
        }

        const res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregate });
        return res.data;
    };

    getTenancyCount = async () => {
        let groupQ = {
            $group: {
                _id: null,
                noTenancy: { $sum: { $cond: [{ $eq: ["$towerDetails.tenancyCount", 0] }, 1, 0] } },
                singleTenancy: { $sum: { $cond: [{ $eq: ["$towerDetails.tenancyCount", 1] }, 1, 0] } },
                doubleTenancy: { $sum: { $cond: [{ $eq: ["$towerDetails.tenancyCount", 2] }, 1, 0] } },
                multiTenancy: { $sum: { $cond: [{ $gt: ["$towerDetails.tenancyCount", 2] }, 1, 0] } },
                avgTenancy: { $avg: "$towerDetails.tenancyCount" },
            },
        };
        let aggregateQuery = [];
        if (this.queryArray.length > 0) {
            aggregateQuery.push({
                $match: {
                    $and: [...this.queryArray],
                },
            });
            aggregateQuery.push(groupQ);
        } else {
            aggregateQuery.push(groupQ);
        }
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };
    getStateWiseData = async (avgTenancy) => {
        let resp = await axios.put(this.url + "nq-towers", { operation: "distinct", key: "towerDetails.towerConfig.towerType" });

        let towerTypes = resp.data;
        let obj = { _id: "$siteDetails.location.state" };
        var towerTyNMMap = { GBT: "Self Support", GBM: "Monopole" };
        if (towerTypes && towerTypes.length) {
            towerTypes.forEach((t) => {
                obj[(towerTyNMMap[t] ? towerTyNMMap[t] + "/" : "") + t] = { $sum: { $cond: [{ $eq: ["$towerDetails.towerConfig.towerType", t] }, 1, 0] } };
            });
        }

        let defectQuery = {
            siteWithDefects: { $sum: { $cond: [{ $or: [{ $gte: ["$towerDetails.totalTowerDefects", 1] }, { $gte: ["$towerDetails.totalSiteDefects", 1] }] }, 1, 0] } },
            sitesBelowAvgTenancy: { $sum: { $cond: [{ $lt: ["$towerDetails.tenancyCount", avgTenancy] }, 1, 0] } },
        };
        obj = Object.assign(obj, defectQuery);

        let aggregateQuery = [{ $group: obj }];

        if (this.queryArray.length > 0) {
            aggregateQuery = [
                {
                    $match: {
                        $and: [...this.queryArray],
                    },
                },
                ...aggregateQuery,
            ];
        }

        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getTowerQuery = async (query, projection) => {
        let q = {
            filter: query,
        };
        if (this.queryArray.length > 0) {
            q.filter = {
                $and: [...this.queryArray, query],
            };
        }

        let operation = q;
        if (projection) operation.projection = projection;
        let res = await axios.post(this.url + "nq-towers", operation);
        return res.data;
    };

    getStateWiseTenancyCount = async (tenancyQuery) => {
        let aggregateQuery = [{ $match: { "towerDetails.tenancyCount": tenancyQuery } }, { $group: { _id: "$siteDetails.location.state", count: { $sum: 1 } } }];

        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getWeatherData = async () => {
        let aggregateQuery = [{ $match: { "condition.code": { $in: [1117, 1171, 1195, 1225, 1237, 1246, 1258, 1264, 1282] } } }, { $group: { _id: { code: "$condition.code", description: "$condition.text" }, count: { $sum: 1 } } }];
        let res = await axios.put(this.url + "nq-towers-weather", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getSitesWithRust = async () => {
        let aggregateQuery = [
            {
                $addFields: {
                    rcount: {
                        $size: "$towerDefects.rustArea",
                    },
                    fcount: {
                        $size: "$towerDefects.rustedFasteners",
                    },
                },
            },
            {
                $match: {
                    $or: [{ rcount: { $gt: 0 } }, { fcount: { $gt: 0 } }],
                },
            },
        ];
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getSitesBelowAvgTenancy = async (avg) => {
        const res = await axios.post(this.url + "nq-towers", { filter: { "towerDetails.tenancyCount": { $lt: avg } } });
        return res.data;
    };
    getSitesAboveAvgTenancy = async (avg) => {
        const res = await axios.post(this.url + "nq-towers", { filter: { "towerDetails.tenancyCount": { $gt: avg } } });
        return res.data;
    };

    getTenancyTypeWise = async () => {
        let aggregateQuery = [
            { $unwind: "$tenants.antennas" },
            {
                $group: {
                    _id: "$tenants.antennas.tennantName",
                    GBTSet: { $addToSet: { $cond: [{ $eq: ["$towerDetails.towerConfig.towerType", "GBT"] }, "$customerSiteid", "$REMOVE"] } },
                    RTTSet: { $addToSet: { $cond: [{ $eq: ["$towerDetails.towerConfig.towerType", "RTT"] }, "$customerSiteid", "$REMOVE"] } },
                    GBMSet: { $addToSet: { $cond: [{ $eq: ["$towerDetails.towerConfig.towerType", "GBM"] }, "$customerSiteid", "$REMOVE"] } },
                    RTPSet: { $addToSet: { $cond: [{ $eq: ["$towerDetails.towerConfig.towerType", "RTP"] }, "$customerSiteid", "$REMOVE"] } },
                },
            },
            { $match: { _id: { $ne: "" } } },
            { $project: { _id: 1, GBT: { $size: "$GBTSet" }, GBM: { $size: "$GBMSet" }, RTT: { $size: "$RTTSet" }, RTP: { $size: "$RTPSet" } } },
        ];
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getHistoricalWeather = async (myxTowerid) => {
        let aggregateQuery = [
            { $match: { "_id.myxTowerid": myxTowerid } },
            { $group: { _id: { myxTowerId: "$_id.myxTowerid" }, maxWindSpeedKph: { $max: "$maxwind_kph" }, maxTemp: { $max: "$maxtemp_c" }, minTemp: { $min: "$mintemp_c" }, totalprecip: { $sum: "$totalprecip_mm" } } },
            { $project: { _id: 0, maxWindSpeed: { $multiply: ["$maxWindSpeedKph", 0.277778] }, maxTemp: 1, minTemp: 1, totalprecip: 1 } },
        ];
        let res = await axios.put(this.url + "nq-towers-weather", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getWeatherEvents = async (myxTowerId) => {
        let aggregateQuery = [
            { $match: { "_id.myxTowerid": myxTowerId, "condition.code": { $in: [1117, 1171, 1195, 1225, 1237, 1246, 1258, 1264, 1282] } } },
            { $group: { _id: { myxTowerId: "$_id.myxTowerid", condition: "$condition.text" }, count: { $sum: 1 }, dates: { $push: "$_id.date" } } },
            { $project: { condition: 1, count: 1, dates: 1 } },
        ];
        let res = await axios.put(this.url + "nq-towers-weather", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getOccupancyByTenants = async () => {
        // let resp = await axios.put(this.url + 'nq-towers', { "operation": "distinct", "key": "towerDetails.towerConfig.towerType" });
        // let towerTypes = resp.data;
        let grpObj = { _id: "$tenants.antennas.tennantName" },
            projectObj = { _id: 1 };
        // if (towerTypes && towerTypes.length) {
        //     towerTypes.forEach(t => {
        //         grpObj[t + "Set"] = { "$addToSet": { "$cond": [{ "$eq": ["$towerDetails.towerConfig.towerType", t] }, "$customerSiteid", "$REMOVE"] } };
        //         projectObj[t] = {"$size": "$" + t + "Set"}
        //     });
        // }

        grpObj["total"] = { $addToSet: "$customerSiteid" };
        projectObj["Total"] = { $size: "$total" };
        let match = { _id: { $ne: "" } };
        if (this.queryArray.length > 0) {
            match = {
                $and: [...this.queryArray, { _id: { $ne: "" } }],
            };
        }

        let aggregateQuery = [{ $unwind: "$tenants.antennas" }, { $group: grpObj }, { $match: match }, { $sort: { _id: 1 } }, { $project: projectObj }];

        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getTowersWithDG = async () => {
        let filter = {
            $and: [{ "siteEquipment.dieselGenerator.count": { $eq: 1 } }],
        };
        const res = await axios.post(this.url + "nq-towers", { filter: filter });
        return res.data;
    };

    getTowerWithSplitAC = async () => {
        let filter = {
            $and: [{ "siteEquipment.splitAC.count": { $eq: 1 } }],
        };
        const res = await axios.post(this.url + "nq-towers", { filter: filter });
        return res.data;
    };

    getTowerWithPrecisionAC = async () => {
        let filter = {
            $and: [{ "siteEquipment.precisionAc.count": { $eq: 1 } }],
        };
        const res = await axios.post(this.url + "nq-towers", { filter: filter });
        return res.data;
    };

    getTowerWithBatteryBank = async () => {
        let filter = {
            $and: [{ "siteEquipment.batteryBank.present": { $eq: "Yes" } }],
        };
        const res = await axios.post(this.url + "nq-towers", { filter: filter });
        return res.data;
    };

    getTotalSitesWithDefects = async () => {
        let aggregateQuery = [
            {
                $group: {
                    _id: null,
                    totalSites: { $addToSet: "$customerSiteid" },
                    sitesWithDefects: { $addToSet: { $cond: [{ $or: [{ $gt: ["$towerDetails.totalSiteDefects", 0] }, { $gt: ["$towerDetails.totalTowerDefects", 0] }] }, "$customerSiteid", "$REMOVE"] } },
                },
            },
        ];
        if (this.queryArray.length > 0) {
            aggregateQuery = [
                {
                    $match: {
                        $and: [...this.queryArray],
                    },
                },
                ...aggregateQuery,
            ];
        }

        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getAllDefectsInstancesAndSites = async () => {
        let aggregateQuery = [
            {
                $addFields: {
                    rustAreaLen: { $size: "$towerDefects.rustArea" },
                },
            },
            { $match: { $or: [{ "towerDetails.totalSiteDefects": { $gt: 0 } }, { "towerDetails.totalTowerDefects": { $gt: 0 } }, { rustAreaLen: { $gte: 1 } }] } },
            {$addFields: {
                looseMissingBolts: {$ifNull: [ "$towerDefects.looseMissingBolts", []] },
                vegetation: {$ifNull: [ "$towerDefects.vegetation", []] },
                chokedDrainHoles: {$ifNull: [ "$siteDefects.chokedDrainHoles", []] },
                rustingMissingStepsOnStairs: {$ifNull: [ "$siteDefects.rustingMissingStepsOnStairs", []] },
            }},
            
            {
                $addFields: {
                    // "rustAreaLen": { "$size": '$towerDefects.rustArea' },
                    looseMissingBoltsLen: { $size: "$looseMissingBolts" },
                    towerLegDamageLen: { $size: "$towerDefects.towerLegDamage" },
                    towerMembersMissingDamagedLen: { $size: "$towerDefects.towerMembersMissingDamaged" },
                    peeledOffPaintLen: { $size: "$towerDefects.peeledOffPaint" },
                    boltHoleMismatchLen: { $size: "$towerDefects.boltHoleMismatch" },
                    improperGroutingGroutMissingLen: { $size: "$towerDefects.improperGroutingGroutMissing" },
                    foundationMinorCracksDamagesLen: { $size: "$towerDefects.foundationMinorCracksDamages" },
                    excessFlangeGapGussetPlateGapLen: { $size: "$towerDefects.excessFlangeGapGussetPlateGap" },
                    tackWeldingOfFoundationBoltsLen: { $size: "$towerDefects.tackWeldingOfFoundationBolts" },
                    vegetationLen: { $size: "$vegetation" },

                    chokedDrainHolesLen: { $size: "$chokedDrainHoles" },
                    rustingMissingStepsOnStairsLen: { $size: "$rustingMissingStepsOnStairs" },
                },
            },
            {
                $group: {
                    _id: null,
                    rustAreaSites: { $addToSet: { $cond: [{ $gt: ["$rustAreaLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    rustAreaCnt: { $sum: "$rustAreaLen" },

                    vegetationSites: { $addToSet: { $cond: [{ $gt: ["$vegetationLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    vegetationCnt: { $sum: "$vegetationLen" },

                    looseMissingBoltsSites: { $addToSet: { $cond: [{ $gt: ["$looseMissingBoltsLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    looseMissingBoltsCnt: { $sum: "$looseMissingBoltsLen" },

                    towerLegDamageSites: { $addToSet: { $cond: [{ $gt: ["$towerLegDamageLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    towerLegDamageCnt: { $sum: "$towerLegDamageLen" },

                    towerMembersMissingDamagedSites: { $addToSet: { $cond: [{ $gt: ["$towerMembersMissingDamagedLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    towerMembersMissingDamagedCnt: { $sum: "$towerMembersMissingDamagedLen" },

                    peeledOffPaintSites: { $addToSet: { $cond: [{ $gt: ["$peeledOffPaintLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    peeledOffPaintCnt: { $sum: "$peeledOffPaintLen" },

                    improperGroutingGroutMissingSites: { $addToSet: { $cond: [{ $gt: ["$improperGroutingGroutMissingLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    improperGroutingGroutMissingCnt: { $sum: "$improperGroutingGroutMissingLen" },

                    boltHoleMismatchSites: { $addToSet: { $cond: [{ $gt: ["$boltHoleMismatchLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    boltHoleMismatchCnt: { $sum: "$boltHoleMismatchLen" },

                    foundationMinorCracksDamagesSites: { $addToSet: { $cond: [{ $gt: ["$foundationMinorCracksDamagesLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    foundationMinorCracksDamagesCnt: { $sum: "$foundationMinorCracksDamagesLen" },

                    towerTiltSites: { $addToSet: { $cond: [{ $eq: ["$towerDefects.towerTilt.present", "Yes"] }, "$customerSiteid", "$REMOVE"] } },
                    severeDamagesToFoundationSites: { $addToSet: { $cond: [{ $eq: ["$towerDefects.severeDamagesToFoundation", "Yes"] }, "$customerSiteid", "$REMOVE"] } },
                    foundationDamageImageSites: { $addToSet: { $cond: [{ $eq: ["$towerDefects.foundationDamageImage", "Yes"] }, "$customerSiteid", "$REMOVE"] } },

                    excessFlangeGapGussetPlateGapSites: { $addToSet: { $cond: [{ $gt: ["$excessFlangeGapGussetPlateGapLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    excessFlangeGapGussetPlateGapCnt: { $sum: "$excessFlangeGapGussetPlateGapLen" },

                    tackWeldingOfFoundationBoltsSites: { $addToSet: { $cond: [{ $gt: ["$tackWeldingOfFoundationBoltsLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    tackWeldingOfFoundationBoltsCnt: { $sum: "$tackWeldingOfFoundationBoltsLen" },

                    //Site Queries
                    chokedDrainHolesSites: { $addToSet: { $cond: [{ $gt: ["$chokedDrainHolesLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    chokedDrainHolesCnt: { $sum: "$chokedDrainHolesLen" },

                    rustingMissingStepsOnStairsSites: { $addToSet: { $cond: [{ $gt: ["$rustingMissingStepsOnStairsLen", 0] }, "$customerSiteid", "$REMOVE"] } },
                    rustingMissingStepsOnStairsCnt: { $sum: "$rustingMissingStepsOnStairsLen" },

                    fencingBarbedWiringDamagesSites: { $addToSet: { $cond: [{ $eq: ["$siteDefects.fencingBarbedWiringDamages", "Yes"] }, "$customerSiteid", "$REMOVE"] } },

                    towerDefectsCnt: { $sum: { $cond: [{ $gt: ["$towerDetails.totalTowerDefects", 0] }, 1, 0] } },
                    siteDefectsCnt: { $sum: { $cond: [{ $gt: ["$towerDetails.totalSiteDefects", 0] }, 1, 0] } },
                },
            },
            {
                $project: {
                    _id: 0,
                    towerDefectsCnt: 1,
                    siteDefectsCnt: 1,
                    rustDefectCnt: { $size: "$rustAreaSites" },
                    towerDefects: {
                        towerTilt: { instances: { $size: "$towerTiltSites" }, sites: { $size: "$towerTiltSites" }, towerIds: "$towerTiltSites" },
                        looseMissingBolts: { instances: "$looseMissingBoltsCnt", sites: { $size: "$looseMissingBoltsSites" }, towerIds: "$looseMissingBoltsSites" },
                        towerMembersMissingDamaged: { instances: "$towerMembersMissingDamagedCnt", sites: { $size: "$towerMembersMissingDamagedSites" }, towerIds: "$towerMembersMissingDamagedSites" },
                        rustArea: { instances: "$rustAreaCnt", sites: { $size: "$rustAreaSites" }, towerIds: "$rustAreaSites" },
                        vegetation: { instances: "$vegetationCnt", sites: { $size: "$vegetationSites" }, towerIds: "$vegetationSites" },
                        towerLegDamage: { instances: "$towerLegDamageCnt", sites: { $size: "$towerLegDamageSites" }, towerIds: "$towerLegDamageSites" },
                        peeledOffPaint: { instances: "$peeledOffPaintCnt", sites: { $size: "$peeledOffPaintSites" }, towerIds: "$peeledOffPaintSites" },
                        severeDamagesToFoundation: { instances: { $size: "$severeDamagesToFoundationSites" }, sites: { $size: "$severeDamagesToFoundationSites" }, towerIds: "$severeDamagesToFoundationSites" },
                        foundationDamageImage: { instances: { $size: "$foundationDamageImageSites" }, sites: { $size: "$foundationDamageImageSites" }, towerIds: "$foundationDamageImageSites" },
                        boltHoleMismatch: { instances: "$boltHoleMismatchCnt", sites: { $size: "$boltHoleMismatchSites" }, towerIds: "$boltHoleMismatchSites" },
                        improperGroutingGroutMissing: { instances: "$improperGroutingGroutMissingCnt", sites: { $size: "$improperGroutingGroutMissingSites" }, towerIds: "$improperGroutingGroutMissingSites" },
                        foundationMinorCracksDamages: { instances: "$foundationMinorCracksDamagesCnt", sites: { $size: "$foundationMinorCracksDamagesSites" }, towerIds: "$foundationMinorCracksDamagesSites" },
                        excessFlangeGapGussetPlateGap: { instances: "$excessFlangeGapGussetPlateGapCnt", sites: { $size: "$excessFlangeGapGussetPlateGapSites" }, towerIds: "$excessFlangeGapGussetPlateGapSites" },
                        tackWeldingOfFoundationBolts: { instances: "$tackWeldingOfFoundationBoltsCnt", sites: { $size: "$tackWeldingOfFoundationBoltsSites" }, towerIds: "$tackWeldingOfFoundationBoltsSites" },
                    },

                    siteDefects: {
                        chokedDrainHoles: { instances: "$chokedDrainHolesCnt", sites: { $size: "$chokedDrainHolesSites" }, towerIds: "$chokedDrainHolesSites" },
                        rustingMissingStepsOnStairs: { instances: "$rustingMissingStepsOnStairsCnt", sites: { $size: "$rustingMissingStepsOnStairsSites" }, towerIds: "$rustingMissingStepsOnStairsSites" },
                        fencingBarbedWiringDamages: { instances: { $size: "$fencingBarbedWiringDamagesSites" }, sites: { $size: "$fencingBarbedWiringDamagesSites" }, towerIds: "$fencingBarbedWiringDamagesSites" },
                    },
                },
            },
        ];
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };
    getRustSeverityData = async () => {
        let aggregateQuery = [{ $unwind: "$towerDefects.rustArea" }, { $group: { _id: "$towerDefects.rustArea.severity", instances: { $sum: 1 }, sites: { $addToSet: "$customerSiteid" } } }, {"$match": { "_id": {"$ne": ""}}}, {"$sort": {"_id": -1}}, { $project: { _id: 1, instances: 1, sites: { $size: "$sites" }, towerIds: "$sites" } }];
        if (this.queryArray.length > 0) {
            aggregateQuery = [
                {
                    $match: {
                        $and: [...this.queryArray, { "towerDetails.totalTowerDefects": { $gt: 0 } }],
                    },
                },
                ...aggregateQuery,
            ];
        } else {
            aggregateQuery = [
                {
                    $match: { "towerDetails.totalTowerDefects": { $gt: 0 } },
                },
                ...aggregateQuery,
            ];
        }
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getStateWiseMenu = async () => {
        let groupQ = {
            $group: { _id: { state: "$siteDetails.location.state", city: "$siteDetails.location.city" }, towers: { $addToSet: { towerid: "$customerSiteid", siteid: "$myxTowerid", lat: "$siteDetails.location.latitude", lng: "$siteDetails.location.longitude" } } },
        };
        let projectQ = { $project: { _id: 0, state: "$_id.state", city: "$_id.city", towers: 1 } };
        let aggregateQuery = [];
        if (this.queryArray.length > 0) {
            aggregateQuery.push({
                $match: {
                    $and: [...this.queryArray],
                },
            });
            aggregateQuery.push(groupQ);
            aggregateQuery.push(projectQ);
        } else {
            aggregateQuery.push(groupQ);
            aggregateQuery.push(projectQ);
        }

        const res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getStateWiseInProgressMenu = async () => {
        let groupQ = { $group: { _id: { state: "$state", city: "$city" }, towers: { $addToSet: { towerid: "$customerSiteid", siteid: "$myxTowerid", region: "$region", invcode: "$invcode" } } } };
        let projectQ = { $project: { _id: 0, state: "$_id.state", city: "$_id.city", towers: 1 } };
        let aggregateQuery = [];
        if (this.queryArray.length > 0) {
            aggregateQuery.push({
                $match: {
                    $and: [...this.queryArray],
                },
            });
            aggregateQuery.push(groupQ);
            aggregateQuery.push(projectQ);
        } else {
            aggregateQuery.push(groupQ);
            aggregateQuery.push(projectQ);
        }

        const res = await axios.put(this.url + "nq-inprogress-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getWeatherByCityName = async (city) => {
        const uri = weatherUrl + "?q=" + city + "&appid=" + apiKey + "&units=Metric";
        const res = await axios.get(uri);
        return res.data;
    };
    getTowerWithNoTenancy = async () => {
        const res = await axios.post(this.url + "nq-towers", { filter: { "towerDetails.tenancyCount": 0 } });
        return res.data;
    };
    getTowerWithFencingDamage = async () => {
        const res = await axios.post(this.url + "nq-towers", { filter: { "siteDefects.fencingBarbedWiringDamages": "Yes" } });
        return res.data;
    };
    getTowerInHighWindZone = async () => {
        const res = await axios.post(this.url + "nq-towers", { filter: { "siteDetails.environmental.windZone": { $gt: 2 } } });
        return res.data;
    };
    getTowerInHighSeismicZone = async () => {
        const res = await axios.post(this.url + "nq-towers", { filter: { "siteDetails.environmental.seismicZone": { $gt: 2 } } });
        return res.data;
    };
    getTowersWithStructuralDefects = async () => {
        const res = await axios.post(this.url + "nq-towers", {
            filter: {
                $or: [
                    { "towerDefects.towerMembersMissingDamaged": { $size: 1 } },
                    { "towerDefects.looseMissingBolts": { $size: 1 } },
                    { "towerDefects.rustArea": { $size: 1 } },
                    { "towerDefects.towerTilt": { $size: 1 } },
                    { "towerDefects.towerLegDamage": { $size: 1 } },
                    { "towerDefects.peeledOffPaint": { $size: 1 } },
                    { "towerDefects.severeDamagesToFoundation": { $ne: "" } },
                    { "towerDefects.boltHoleMismatch": { $size: 1 } },
                    { "towerDefects.improperGroutingGroutMissing": { $size: 1 } },
                    { "towerDefects.rustedFasteners": { $size: 1 } },
                    { "towerDefects.foundationMinorCracksDamages": { $size: 1 } },
                    { "towerDefects.excessFlangeGapGussetPlateGap": { $size: 1 } },
                    { "towerDefects.tackWeldingOfFoundationBolts": { $size: 1 } },
                ],
            },
        });
        return res.data;
    };
    getOverloadSites = async () => {
        let aggregateQuery = [
            { $match: { "towerDetails.capacity.loadCapacityBuilt": { $ne: "" } } },
            { $addFields: { builtCapacity: { $cond: [{ $gt: ["$towerDetails.capacity.usedLoadCapacityWithWindLoad", "$towerDetails.capacity.loadCapacityBuilt"] }, true, false] } } },
            { $match: { builtCapacity: true } },
        ];
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getSiteIDs = async () => {
        let aggregateQuery = [{ $match: { tenant: { $exists: true, $ne: [] } } }, { $unwind: "$tenant" }, { $addFields: { tenantLoading: { $cond: [{ $gt: ["$tenant.allocatedLoad", "$tenant.usedLoad"] }, true, false] } } }, { $match: { tenantLoading: true } }, { $group: { _id: "$customerSiteid" } }];
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getMultiSites = async (sitesIDs) => {
        const res = await axios.post(this.url + "nq-towers", {
            filter: {
                $or: sitesIDs,
            },
        });
        return res.data;
    };

    getDieselCapacityWiseCount = async () => {
        let match = {};

        if (this.queryArray.length > 0) {
            match = {
                $and: [...this.queryArray, { "siteEquipment.dieselGenerator.capacity": { $exists: true, $ne: "" } }],
            };
        } else {
            match = { "siteEquipment.dieselGenerator.capacity": { $exists: true, $ne: "" } };
        }

        let aggregateQuery = [{ $match: match }, { $group: { _id: "$siteEquipment.dieselGenerator.capacity", count: { $sum: 1 } } }];
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getStateWiseDGCapacityCount = async (capacityQuery) => {
        let aggregateQuery = [{ $match: capacityQuery }, { $group: { _id: "$siteDetails.location.state", count: { $sum: 1 } } }];

        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getStateWiseInventoryCount = async () => {
        let groupQ = {
            $group: {
                _id: "$siteDetails.location.state",
                dieselGenerator: { $addToSet: { $cond: [{ $and: [{ $ne: ["$siteEquipment.dieselGenerator.count", ""] }, { $gt: ["$siteEquipment.dieselGenerator.count", 0] }] }, "$customerSiteid", "$REMOVE"] } },
                splitAC: { $addToSet: { $cond: [{ $and: [{ $ne: ["$siteEquipment.splitAC.count", ""] }, { $gt: ["$siteEquipment.splitAC.count", 0] }] }, "$customerSiteid", "$REMOVE"] } },
                precisionAc: { $addToSet: { $cond: [{ $and: [{ $ne: ["$siteEquipment.precisionAc.count", ""] }, { $gt: ["$siteEquipment.precisionAc.count", 0] }] }, "$customerSiteid", "$REMOVE"] } },
                transformer: { $addToSet: { $cond: [{ $eq: ["$siteEquipment.transformer.present", "Yes"] }, "$customerSiteid", "$REMOVE"] } },
                batteryBank: { $addToSet: { $cond: [{ $eq: ["$siteEquipment.batteryBank.present", "Yes"] }, "$customerSiteid", "$REMOVE"] } },
            },
        };
        let aggregateQuery = [];
        if (this.queryArray.length > 0) {
            aggregateQuery.push({
                $match: {
                    $and: [...this.queryArray],
                },
            });
            aggregateQuery.push(groupQ);
        } else {
            aggregateQuery.push(groupQ);
        }

        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getAccessibilityData = async (distanceFromRoad) => {
        let groupQ = {
            $group: {
                _id: null,
                total: { $addToSet: "$customerSiteid" },
                fenced: { $addToSet: { $cond: [{ $eq: ["$siteDetails.physical.fenced", "Yes"] }, "$customerSiteid", "$REMOVE"] } },
                notFenced: { $addToSet: { $cond: [{ $eq: ["$siteDetails.physical.fenced", "No"] }, "$customerSiteid", "$REMOVE"] } },
                unmotarable: { $addToSet: { $cond: [{ $eq: ["$siteDetails.access.motorable", "No"] }, "$customerSiteid", "$REMOVE"] } },
                fromRoad: { $addToSet: { $cond: [{ $gt: ["$siteDetails.access.distanceFromRoad", distanceFromRoad] }, "$customerSiteid", "$REMOVE"] } },
                withSolarPotential: { $addToSet: { $cond: [{ $and: [{ $ne: ["$siteDetails.solarPotential", ""] }, { $gt: ["$siteDetails.solarPotential", 0] }] }, "$customerSiteid", "$REMOVE"] } },
            },
        };
        let aggregateQuery = [];
        if (this.queryArray.length > 0) {
            aggregateQuery.push({
                $match: {
                    $and: [...this.queryArray],
                },
            });
            aggregateQuery.push(groupQ);
        } else {
            aggregateQuery.push(groupQ);
        }

        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getTowerTypeWiseCount = async () => {
        let aggregateQuery = [
            {
                $group: {
                    _id: {
                        siteType: "$towerDetails.towerConfig.siteType",
                        towerSubType: "$towerDetails.towerConfig.towerSubType",
                    },
                    Count: { $sum: 1 },
                },
            },
            {
                $group: {
                    _id: "$_id.siteType",
                    towerSubTypes: {
                        $push: {
                            towerSubType: "$_id.towerSubType",
                            count: "$Count",
                        },
                    },
                    count: { $sum: "$Count" },
                },
            },
        ];
        if (this.queryArray.length > 0) {
            aggregateQuery = [
                {
                    $match: {
                        $and: [...this.queryArray],
                    },
                },
                ...aggregateQuery,
            ];
        }

        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getWeatherGraphData = async (id, from_data, to_data) => {
        let filter = { filter: { "_id.myxTowerid": id, date: { $gte: from_data, $lte: to_data } }, projections: { mintemp_c: 1, maxtemp_c: 1, maxwind_kph: 1, date: 1 } };

        const res = await axios.post(this.url + "nq-towers-weather", filter);
        return res.data;
    };

    getZoningWiseCount = async () => {
        let aggregateQuery = [{ $group: { _id: "$siteDetails.zoningArea", count: { $sum: 1 } } }, { $match: { _id: { $ne: "" } } }];
        if (this.queryArray.length > 0) {
            aggregateQuery = [
                {
                    $match: {
                        $and: [...this.queryArray],
                    },
                },
                ...aggregateQuery,
            ];
        }
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getFreeSlotsCount = async () => {
        let aggregateQuery = [
            {
                $group: {
                    _id: null,
                    all: { $addToSet: { $cond: [{ $eq: ["$towerDetails.tenancyCount", 0] }, "$customerSiteid", "$REMOVE"] } },
                    topFreeOtherOccupied: {
                        $addToSet: { $cond: [{ $and: [{ $eq: ["$towerDetails.slot0.filled", "no"] }, { $or: [{ $eq: ["$towerDetails.slot1.filled", "yes"] }, { $eq: ["$towerDetails.slot2.filled", "yes"] }, { $eq: ["$towerDetails.slot3.filled", "yes"] }] }] }, "$customerSiteid", "$REMOVE"] },
                    },
                    "2nd onwards": { $addToSet: { $cond: [{ $and: [{ $eq: ["$towerDetails.slot0.filled", "yes"] }, { $eq: ["$towerDetails.slot1.filled", "no"] }, { $eq: ["$towerDetails.slot2.filled", "no"] }, { $eq: ["$towerDetails.slot3.filled", "no"] }] }, "$customerSiteid", "$REMOVE"] } },
                    "3rd onwards": { $addToSet: { $cond: [{ $and: [{ $eq: ["$towerDetails.slot0.filled", "yes"] }, { $eq: ["$towerDetails.slot1.filled", "yes"] }, { $eq: ["$towerDetails.slot2.filled", "no"] }, { $eq: ["$towerDetails.slot3.filled", "no"] }] }, "$customerSiteid", "$REMOVE"] } },
                    "4th": { $addToSet: { $cond: [{ $and: [{ $eq: ["$towerDetails.slot0.filled", "yes"] }, { $eq: ["$towerDetails.slot1.filled", "yes"] }, { $eq: ["$towerDetails.slot2.filled", "yes"] }, { $eq: ["$towerDetails.slot3.filled", "no"] }] }, "$customerSiteid", "$REMOVE"] } },
                },
            },
        ];
        if (this.queryArray.length > 0) {
            aggregateQuery = [
                {
                    $match: {
                        $and: [...this.queryArray],
                    },
                },
                ...aggregateQuery,
            ];
        }
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getWeatherEventWiseCount = async (fromDate, toDate, conditions) => {
        let groupObj = { _id: "$myxTowerid", count: { $sum: 1 }};
        if(conditions.length){
            conditions.forEach(c => {
                groupObj[c] = { $sum: { $cond: [{ $eq: ["$condition.text", c] }, 1, 0] } }
            });
        }
        let aggregateQuery = [
            {
                $match: {
                    date: { $gte: fromDate, $lte: toDate },
                    "condition.text": {
                        $in: conditions,
                    },
                },
            },
            { $group: groupObj },
            { $match: { count: { $gte: 1 } } },
        ];

        let res = await axios.put(this.url + "nq-towers-weather", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getTransportData = async () => {
        let aggregateQuery = [
            {
                $group: {
                    _id: null,
                    fiberized: { $addToSet: { $cond: [{ $eq: ["$towerDetails.fiberized", 1] }, "$customerSiteid", "$REMOVE"] } },
                    repeater: { $addToSet: { $cond: [{ $eq: ["$towerDetails.repeater", 1] }, "$customerSiteid", "$REMOVE"] } },
                    microwave: { $addToSet: { $cond: [{ $eq: ["$towerDetails.mwsite", 1] }, "$customerSiteid", "$REMOVE"] } },
                },
            },
        ];
        if (this.queryArray.length > 0) {
            aggregateQuery = [
                {
                    $match: {
                        $and: [...this.queryArray],
                    },
                },
                ...aggregateQuery,
            ];
        }
        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };

    getAntennaDifferenceCount = async () => {
        let aggregateQuery = [
            { $match: { atcTowerLoading: { $exists: true } } },
            {
                $group: {
                    _id: null,
                    "RF > Billing": { $addToSet: { $cond: [{ $gt: ["$atcTowerLoading.actualRFcount", "$atcTowerLoading.deginedRFcount"] }, "$customerSiteid", "$REMOVE"] } },
                    "RF < Billing": { $addToSet: { $cond: [{ $gt: ["$atcTowerLoading.deginedRFcount", "$atcTowerLoading.actualRFcount"] }, "$customerSiteid", "$REMOVE"] } },
                    "MW > Billing": { $addToSet: { $cond: [{ $gt: ["$atcTowerLoading.actualMWcount", "$atcTowerLoading.deginedMWcount"] }, "$customerSiteid", "$REMOVE"] } },
                    "MW < Billing": { $addToSet: { $cond: [{ $gt: ["$atcTowerLoading.deginedMWcount", "$atcTowerLoading.actualMWcount"] }, "$customerSiteid", "$REMOVE"] } },
                    "RRU > Billing": { $addToSet: { $cond: [{ $gt: ["$atcTowerLoading.actualRRUcount", "$atcTowerLoading.deginedRRUcount"] }, "$customerSiteid", "$REMOVE"] } },
                    "RRu < Billing": { $addToSet: { $cond: [{ $gt: ["$atcTowerLoading.deginedRRUcount", "$atcTowerLoading.actualRRUcount"] }, "$customerSiteid", "$REMOVE"] } },
                },
            },
        ];

        let res = await axios.put(this.url + "nq-towers", { operation: "aggregate", aggregate: aggregateQuery });
        return res.data;
    };
}
